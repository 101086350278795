import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Condo } from '../../../api/model/condo';
import { UtilService } from '../../../services/util.service';
import { CommentService } from '../../../api/service/comment.service';
import { User } from '../../../api/model/user';
import { ToastrService } from 'ngx-toastr';
import { FeedCommentComponent } from '../feed-comment/feed-comment.component';
import { PrivateOccurrence } from '../../../api/model/occurrence/occurrence.private';
import { ModalShowDataViewersComponent } from '../../../components/modal-show-data-viewers/modal-show-data-viewers.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-private-card',
  templateUrl: './private-card.component.html',
  styleUrls: ['./private-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivateCardComponent implements OnChanges {
  @ViewChild(FeedCommentComponent, { static: true }) commentsComponent;

  @Input()
  occurrence: PrivateOccurrence;

  @Input()
  condo: Condo;

  @Input()
  user: User;

  @Input()
  dataTestIdIndex: number;

  @Output()
  delete: EventEmitter<PrivateOccurrence> = new EventEmitter<PrivateOccurrence>();

  @Output()
  edit: EventEmitter<PrivateOccurrence> = new EventEmitter<PrivateOccurrence>();

  @Output()
  close: EventEmitter<PrivateOccurrence> = new EventEmitter<PrivateOccurrence>();

  STATUS_VALUES = {
    OPENED: {
      labelColor: '#387ef5',
      labelClass: 'badge-subtle-primary'
    },
    CLOSED: {
      labelColor: '#32DB64',
      labelClass: 'badge-success'
    }
  };

  isAdmin;
  canEdit;
  canClose;
  canDelete;

  data: any = {};

  commentsTotal;
  loadingComments;

  constructor(
    private utilService: UtilService,
    private commentService: CommentService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const occurrence: PrivateOccurrence = changes.occurrence?.currentValue;
    this.isAdmin = changes.user?.currentValue?.isAdmin() || changes.user?.currentValue?.isOwner();
    this.canEdit = occurrence.canEdit(changes.user?.currentValue);
    this.canClose = occurrence.canClose(changes.user?.currentValue);
    this.canDelete = occurrence.canDelete(changes.user?.currentValue);
    this.data.createdBy =
      occurrence.createdBy?.firstName +
      ' ' +
      occurrence.createdBy?.lastName +
      (occurrence.residence?.identification ? ' - ' + occurrence.residence.identification : '');
    this.data.createdAt = occurrence.createdAt;
    this.data.title = occurrence.title;
    this.data.description = occurrence.description;
    this.data.userPicture = occurrence.createdBy?.picture?.url || 'assets/img/empty-user-picture.png';
    this.data.userPictureThumbnail = occurrence.createdBy?.picture?.thumbnail || 'assets/img/empty-user-picture.png';
    this.data.timeAgo = occurrence.title;
    this.data.protocol = occurrence.protocol;
    this.data.statusLabel = occurrence.statusLabel.toUpperCase();
    this.data.status = occurrence.status;
    this.data.isClosed = occurrence.isClosed();
    this.data.closingDetails = occurrence.closingDetails;
    this.data.viewers = {
      residences: (occurrence.viewers?.residences || []).map(res => res?.identification).join(', '),
      users: (occurrence.viewers?.users || []).map(u => u?.firstName + ' ' + u?.lastName).join(', ')
    };
    this.data.updatedAt = occurrence.updatedAt;
    this.data.updatedBy = occurrence.updatedBy;
    this.commentsTotal = occurrence.commentsTotal;
  }

  loadComments() {
    this.commentsComponent.loadComments();
  }

  onCommentCreated() {
    this.commentsTotal++;
    this.cdr.detectChanges();
  }

  onCommentDeleted() {
    this.commentsTotal--;
    this.cdr.detectChanges();
  }

  showDataViewersModal() {
    const initialState = {
      condo: this.condo,
      model: 'occurrence',
      documentId: this.occurrence._id
    };
    this.modalService.show(ModalShowDataViewersComponent, { initialState, class: 'modal-lg' });
  }
}
