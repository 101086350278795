import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilService } from '../../services/util.service';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { AvailableManufacturers } from '../hardware-devices/hardware-devices';

interface HardwareManufacturerConfig {
  value: string;
  label: string;
  logo: string;
  enabled: boolean;
}

@Component({
  selector: 'app-modal-show-manufacturer-choice',
  templateUrl: 'modal-show-manufacturer-choice.component.html',
  styleUrls: ['modal-show-manufacturer-choice.component.scss']
})
export class ModalShowManufacturerChoiceComponent {
  hardwareManufacturers: Record<AvailableManufacturers, HardwareManufacturerConfig>;
  enabledHardwareManufacturers: HardwareManufacturerConfig[] = [];

  public callbacks: {
    success?: (manufacturer: AvailableManufacturers) => void;
  };

  constructor(
    public bsModalRef: BsModalRef,
    private utilService: UtilService
  ) {
    const condo = this.utilService.getLocalCondo();
    this.hardwareManufacturers = {
      LINEAR: { value: HARDWARES.LINEAR, label: 'Linear', logo: 'assets/img/linear-logo.png', enabled: condo.isLinearEnabled() },
      CONTROL_ID: {
        value: HARDWARES.CONTROL_ID,
        label: 'Control ID',
        logo: 'assets/img/control-id-logo.png',
        enabled: condo.isControlIdEnabled()
      },
      HIKVISION: {
        value: HARDWARES.HIKVISION,
        label: 'Hikvision',
        logo: 'assets/img/hikvision-logo.png',
        enabled: condo.isHikvisionEnabled()
      },
      INTELBRAS: {
        value: HARDWARES.INTELBRAS,
        label: 'Intelbras InControl',
        logo: 'assets/img/incontrol.png',
        enabled: condo.isIntelbrasEnabled()
      },
      INTELBRAS_STAND_ALONE: {
        value: HARDWARES.INTELBRAS_STAND_ALONE,
        label: 'Intelbras',
        logo: 'assets/img/intelbras-logo.png',
        enabled: condo.isIntelbrasStandAloneEnabled()
      },
      UTECH: { value: HARDWARES.UTECH, label: 'uTech', logo: 'assets/img/utech-logo.png', enabled: condo.isUtechEnabled() },
      NICE_CONTROLLER: {
        value: HARDWARES.NICE_CONTROLLER,
        label: 'Controladora Nice',
        logo: 'assets/img/controladora-ethernet-2.png',
        enabled: condo.isNiceControllerEnabled()
      },
      ALPHADIGI: {
        value: HARDWARES.ALPHADIGI,
        label: 'AlphaDigi',
        logo: 'assets/img/alphadigi-logo.png',
        enabled: condo.isAlphaDigiEnabled()
      },
      GAREN: {
        value: HARDWARES.GAREN,
        label: 'Garen',
        logo: 'assets/img/garen-logo.png',
        enabled: condo.isGarenEnabled()
      },
      ZKTECO: {
        value: HARDWARES.ZKTECO,
        label: 'ZKTeco',
        logo: 'assets/img/zkteco-logo.png',
        enabled: condo.isZktecoEnabled()
      },
      BRAVAS: {
        value: HARDWARES.BRAVAS,
        label: 'Bravas',
        logo: 'assets/img/bravas-logo.png',
        enabled: condo.isBravasEnabled()
      },
      XPE: {
        value: HARDWARES.XPE,
        label: 'XPE',
        logo: 'assets/img/intelbras-xpe-logo.png',
        enabled: condo.isXPEEnabled()
      },
      MIP1000IP: {
        value: HARDWARES.MIP1000IP,
        label: 'MIP1000IP',
        logo: 'assets/img/mip1000ip-logo.png',
        enabled: condo.isMIP1000IPEnabled()
      },
      AVICAM: {
        value: HARDWARES.AVICAM,
        label: 'Avicam',
        logo: 'assets/img/avicam-logo.png',
        enabled: condo.isAvicamEnabled()
      }
    };

    const keys = Object.keys(this.hardwareManufacturers);
    keys.forEach(key => {
      if (this.hardwareManufacturers[key].enabled) {
        this.enabledHardwareManufacturers.push(this.hardwareManufacturers[key]);
      }
    });
  }

  selectManufacturer(manufacturer) {
    if (this.callbacks && this.callbacks.success) {
      this.callbacks.success(manufacturer);
    }
    this.bsModalRef.hide();
  }
}
