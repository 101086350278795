import { AfterViewInit, Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { User } from '@api/model/user';
import { OccurrenceService } from '@api/service/occurrence.service';
import { ConstantService } from '../../services/constant.service';
import swal from 'sweetalert2';
import { Occurrence } from '@api/model/interface/occurrence';
import { AdviceCreatorComponent } from '../occurrence.creator/advice/advice.occurrence.creator';
import { PrivateCreatorComponent } from '../occurrence.creator/private/private.occurrence.creator';
import { LightboxComponent } from '../../components/lightbox/lightbox';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Condo } from '@api/model/condo';
import * as moment from 'moment';
import { Error } from '@api/model/error/error';
import { Status } from '@api/model/status';
import { catchError, distinctUntilChanged, filter, map, mergeMap, takeUntil, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { OccurrenceBuilder } from '@api/model/occurrence/occurrence.builder';
import { Observable, of, Subject } from 'rxjs';
import { ResidenceService } from '@api/service/residence.service';
import { Residence } from '@api/model/interface/residence';
import { EcondosQuery } from '@api/model/query';
import { VotingCreatorComponent } from '../occurrence.creator/voting/voting.occurrence.creator';
import { ModalShareLinkComponent } from '../../components/modal-share-link/modal-share-link';
import { ModalCreateAuthorizedPersonComponent } from '../../components/modal-create-authorized-person/modal-create-authorized-person.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { createLinkToShareOccurrence } from '@api/utils';
import { ThemeService } from '../../theme';
import { GateOccurrence } from '@api/model/occurrence/occurrence.gate';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';
import { SessionService } from '@api/service/session.service';

@Component({
  templateUrl: './feed.html',
  animations: [
    trigger('checkAnimation', [
      transition(':enter', [
        style({ transform: 'scale(0.5, 0.5)', opacity: 0 }),
        animate('350ms', style({ transform: 'scale(1,1)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(1, 1)', opacity: 1 }),
        animate('350ms', style({ transform: 'scale(0.1, 0.1)', opacity: 0 }))
      ])
    ])
  ]
})
export class FeedPage implements OnDestroy, AfterViewInit {
  @ViewChild(AdviceCreatorComponent) adviceCreatorComponent: AdviceCreatorComponent;
  // @ViewChild(PublicCreatorComponent) publicCreatorComponent: PublicCreatorComponent;
  @ViewChild(PrivateCreatorComponent) privateCreatorComponent: PrivateCreatorComponent;
  @ViewChild(VotingCreatorComponent) votingCreatorComponent: VotingCreatorComponent;

  @ViewChild(LightboxComponent, { static: true }) lightboxModal: LightboxComponent;

  public occurrences: Array<Occurrence> = [];
  public filterKey;
  public user: User;
  public condo: Condo;
  public userResidences;
  public residencesVoterId;
  public singleOccurrenceLoadingStatus: Status = new Status();

  public isShowingSingleOccurrence = false;

  public occurrenceTypes;
  public occurs;
  public numberOfOccurrencesPerPage;

  public occurrenceStatusValues;

  public baseSuccessCallback;
  public baseErrorCallback;
  public isInfiniting = false;
  public showGoToTop = false;
  public oldScroll = 0;

  filterMaxDate = new Date();
  private routeChangeSubscriber;

  residenceTypeAheadStatus: Status = new Status();

  residenceSearch;
  loadingResidences = false;

  residenceTypeAheadDataSource$: Observable<any>;

  isAdmin = false;
  canCreateOccurrence;
  hasAccessLiberationPermission: boolean;
  canCreateAccessLiberation: boolean;

  canSelfRegistrate: boolean;

  unsubscribe$ = new Subject<void>();

  constructor(
    public occurrenceService: OccurrenceService,
    public sessionService: SessionService,
    public router: Router,
    public route: ActivatedRoute,
    public constantService: ConstantService,
    private toastr: ToastrService,
    private residenceService: ResidenceService,
    private modalService: BsModalService,
    private themeService: ThemeService
  ) {
    this.numberOfOccurrencesPerPage = 10;

    // TODO passar isso para o classe occurrence
    this.occurrenceStatusValues = {
      OPENED: {
        status: 'OPENED',
        label: 'Aberta',
        labelClass: 'badge-primary'
      },
      PENDING: {
        status: 'PENDING',
        label: 'Pendente',
        labelClass: 'badge-warning'
      },
      CLOSED: {
        status: 'CLOSED',
        label: 'Fechada',
        labelClass: 'badge-success'
      },
      CANCELED: {
        status: 'CANCELED',
        label: 'Cancelada',
        labelClass: 'badge-danger'
      }
    };

    const initialMoment = moment().startOf('year');
    const filterBaseInitialDate = initialMoment.toDate();
    const finalMoment = moment();
    const filterBaseEndDate = finalMoment.toDate();

    this.condo = this.sessionService.condoValue;
    this.user = this.sessionService.userValue;

    this.occurrenceTypes = {};

    if (this.condo && !this.condo.isAdvicesDisabled()) {
      this.occurrenceTypes.ADVICE = 'ADVICE';
      this.filterKey = this.occurrenceTypes.ADVICE;
    }

    const hasAccessLiberationPermission = this.user?.getPermissionValue({
      condoId: this.condo._id,
      permission: PERMISSIONS.accessLiberation.view
    });

    const condoId = this.condo._id;

    const isGatekeeperOrAdmin =
      this.user.isAdminOnCondo(condoId) || this.user.isGatekeeperOnCondo(condoId) || this.user.isOwnerOnCondo(condoId);
    const isOnlyUser = this.user.isUserOnCondo(condoId) && !isGatekeeperOrAdmin;

    const canUserCreateAccessLiberation =
      (isOnlyUser && this.condo.generalParams?.accessLiberation?.residentCanRegisterAccessLiberation) || isGatekeeperOrAdmin;

    const isAccessLiberationEnabled = !this.condo.isAccessLiberationDisabled();

    this.canCreateAccessLiberation = isAccessLiberationEnabled && hasAccessLiberationPermission && canUserCreateAccessLiberation;

    //Valida se ele não é apenas zelador, se for não permitir entrar na função, por isso a verificação de cada 'is'
    if (this.user && (this.user.isUser() || this.user.isAdmin() || this.user.isOwner() || this.user.isGatekeeper())) {
      if (this.condo && hasAccessLiberationPermission && (!this.condo.isAccessLiberationDisabled() || !this.condo.isDeliveriesDisabled())) {
        this.occurrenceTypes.GATE = 'GATE';

        if (!this.filterKey) {
          this.filterKey = this.occurrenceTypes.GATE;
        }
      }

      if (this.condo && !this.condo.isOccurrencesDisabled() && !this.user.isGatekeeper()) {
        this.occurrenceTypes.PRIVATE = 'PRIVATE';

        if (!this.filterKey) {
          this.filterKey = this.occurrenceTypes.PRIVATE;
        }
      }

      if (this.condo && !this.condo.isVotingDisabled() && !this.user.isGatekeeper()) {
        this.occurrenceTypes.VOTING = 'VOTING';

        if (!this.filterKey) {
          this.filterKey = this.occurrenceTypes.VOTING;
        }
      }
    }

    this.occurs = {
      PUBLIC: {
        pageNumber: 1,
        type: 'PUBLIC',
        list: [],
        status: new Status(),
        hasMoreData: false,
        label: 'Públicas',
        filterStartDate: filterBaseInitialDate,
        filterEndDate: filterBaseEndDate,
        filterSearchTerm: '',
        filterStatus: '',
        filterStatusOpenedLabel: 'Abertas',
        filterStatusClosedLabel: 'Fechadas',
        showFilter: false,
        hasAccess: true
      },
      PRIVATE: {
        pageNumber: 1,
        type: 'PRIVATE',
        list: [],
        status: new Status(),
        hasMoreData: false,
        label: 'Ocorrências',
        filterStartDate: filterBaseInitialDate,
        filterEndDate: filterBaseEndDate,
        filterSearchTerm: '',
        filterStatus: '',
        filterResidence: '',
        filterStatusOpenedLabel: 'Abertas',
        filterStatusClosedLabel: 'Fechadas',
        showFilter: false,
        hasAccess: true
      },
      VOTING: {
        pageNumber: 1,
        type: 'VOTING',
        list: [],
        status: new Status(),
        hasMoreData: false,
        label: 'Enquetes',
        filterStartDate: filterBaseInitialDate,
        filterEndDate: filterBaseEndDate,
        filterSearchTerm: '',
        filterStatus: '',
        filterStatusOpenedLabel: 'Em andamento',
        filterStatusClosedLabel: 'Encerradas',
        showFilter: false,
        hasAccess: this.condo.hasVotingAccess()
      },
      ADVICE: {
        pageNumber: 1,
        type: 'ADVICE',
        list: [],
        status: new Status(),
        hasMoreData: false,
        label: 'Avisos',
        filterStartDate: filterBaseInitialDate,
        filterEndDate: filterBaseEndDate,
        filterSearchTerm: '',
        filterArchived: '',
        showFilter: false,
        hasAccess: true
      },
      GATE: {
        pageNumber: 1,
        type: 'GATE',
        list: [],
        status: new Status(),
        hasMoreData: false,
        label: 'Portaria',
        filterStartDate: filterBaseInitialDate,
        filterEndDate: filterBaseEndDate,
        filterSearchTerm: '',
        filterStatus: '',
        filterResidence: '',
        filterStatusOpenedLabel: 'Abertas',
        filterStatusClosedLabel: 'Fechadas',
        showFilter: false,
        hasAccess: this.condo.hasGateAccess()
      }
    };

    this.occurs.PUBLIC.status.setAsUnloaded();
    this.occurs.PRIVATE.status.setAsUnloaded();
    this.occurs.ADVICE.status.setAsUnloaded();
    this.occurs.GATE.status.setAsUnloaded();
    this.occurs.VOTING.status.setAsUnloaded();

    this.baseSuccessCallback = occurrences => {
      const currentFilter = this.filterKey;
      this.occurs[currentFilter].list = occurrences;
      this.occurrences = this.occurs[currentFilter].list;
      this.occurs[currentFilter].status.setAsSuccess();
    };

    this.baseErrorCallback = () => {
      const currentFilter = this.filterKey;
      this.occurs[currentFilter].status.setAsError();
    };

    this.initializeTypeAheads();

    this.isAdmin = this.user.isAdmin() || this.user.isOwner();
    this.canCreateOccurrence = this.hasPermissionToCreateOccurrence(this.user, this.condo);
    this.canSelfRegistrate = this.condo.generalParams.accessLiberation.visitorSelfRegistration;
  }

  ngAfterViewInit() {
    this.user = this.sessionService.userValue;
    this.userResidences = [].concat(this.user.residencesUser, this.user.residencesVoter);
    this.condo = this.sessionService.condoValue;
    this.hasAccessLiberationPermission = this.user?.getPermissionValue({
      condoId: this.condo._id,
      permission: PERMISSIONS.accessLiberation.view
    });

    this.residencesVoterId =
      this.user.residencesVoter.map(value => {
        return value._id ? value._id : value;
      }) || [];

    const param = this.route.snapshot.params['param'];

    if (param) {
      if (this.occurrenceTypes[param.toUpperCase()]) {
        this.filterKey = param.toUpperCase();
        this.loadAllOccurrences();
      } else {
        this.loadSingleOccurrence(param);
      }
    } else {
      this.loadAllOccurrences();
    }

    this.routeChangeSubscriber = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      let type = this.route.snapshot.params['param'] || '';
      type = type.toUpperCase();
      if (this.occurrenceTypes[type]) {
        this.isShowingSingleOccurrence = false;
        this.setFilteredOccurrences(type);
      } else {
        this.loadSingleOccurrence(type);
      }
    });
  }

  ngOnDestroy() {
    this.routeChangeSubscriber.unsubscribe();
  }

  hasPermissionToCreateOccurrence(user: User, condo: Condo) {
    if (user.isAdmin() || user.isOwner()) {
      return true;
    }
    if (user.isUser()) {
      return condo.canResidentsCreateOccurrence();
    }
    return false;
  }

  // Evento para exibir/esconder o botão para voltar ao inicio
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = document.body.scrollTop || document.documentElement.scrollTop;

    if ((this.showGoToTop && number > this.oldScroll) || (!this.showGoToTop && number < this.oldScroll)) {
      this.oldScroll = number;
      return;
    }

    if (number > 300 && number > this.oldScroll + 100) {
      this.showGoToTop = true;
    } else if (number < this.oldScroll - 50) {
      this.showGoToTop = false;
    }
  }

  getBaseQueryString(): EcondosQuery {
    const currentFilter = this.filterKey;
    let sort = '-createdAt';
    if (currentFilter === Occurrence.ADVICE_TYPE) {
      sort = '-pin -createdAt';
    }
    const query: EcondosQuery = {
      $select:
        'condo description params _comments pictures createdBy viewers residence userContacts subTypeAttachments updatedBy condoContacts type createdAt votes votingFrom votingUntil voteType canceledBy canceledAt' +
        ' visitorType updatedAt trackingNumbers title tags status protocolMonth protocolSeq protocolYear shouldCall likers hidden isVoting daysAllowed accesses startDate endDate subType archived pin descriptionHtml closingDetails groups accessGroups',
      $populate: [
        {
          path: 'pictures',
          select: 'url thumbnail type format name videoThumbnail'
        },
        '_comments',
        '_commentsTotal',
        {
          path: 'createdBy',
          select: 'firstName lastName picture',
          populate: [
            {
              path: 'picture',
              select: 'url thumbnail type format name'
            }
          ]
        },
        {
          path: 'viewers',
          select: 'users residences',
          populate: [
            {
              path: 'residences',
              select: 'block address number identification type lot'
            },
            {
              path: 'users',
              select: 'firstName lastName picture',
              populate: [
                {
                  path: 'picture',
                  select: 'url thumbnail type format name'
                }
              ]
            }
          ]
        },
        { path: 'residence', select: 'block address number identification type lot' },
        { path: 'userContacts', select: 'firstName lastName ' },
        { path: 'subTypeAttachments', select: 'url thumbnail type format name' },
        { path: 'updatedBy', select: 'firstName lastName' },
        { path: 'canceledBy', select: 'firstName lastName' },
        {
          path: 'condoContacts',
          select: 'firstName lastName condo picture condoVehicles',
          populate: [
            { path: 'picture', select: 'url' },
            { path: 'condoVehicles', select: 'plate' }
          ]
        },
        { path: 'accessGroups', select: 'name' },
        { path: 'groups', select: 'name' }
      ]
    };

    if (this.occurs[currentFilter].showFilter) {
      query['createdAt'] = {
        $gte: moment(this.occurs[currentFilter].filterStartDate).startOf('day').toISOString(),
        $lte: moment(this.occurs[currentFilter].filterEndDate).endOf('day').toISOString()
      };
      if (this.occurs[currentFilter].filterStatus) {
        if (currentFilter == Occurrence.VOTING_TYPE) {
          if (this.occurs[currentFilter].filterStatus == Occurrence.STATUS.OPENED) {
            query['votingUntil'] = { $gte: moment().utc().startOf('day').toISOString() };
          }

          if (this.occurs[currentFilter].filterStatus == Occurrence.STATUS.CLOSED) {
            query['votingUntil'] = { $lte: moment().utc().endOf('day').toISOString() };
          }
        } else {
          query['status'] = this.occurs[currentFilter].filterStatus;
        }
      }

      if (currentFilter == Occurrence.PRIVATE_TYPE || currentFilter == Occurrence.GATE_TYPE) {
        if (this.occurs[currentFilter].filterResidence) {
          if (!query.$and) {
            query.$and = [];
          }
          query.$and.push({
            $or: [
              { residence: this.occurs[currentFilter].filterResidence._id },
              { 'viewers.residences': this.occurs[currentFilter].filterResidence._id }
            ]
          });
        }
      }
    }

    if (currentFilter === Occurrence.ADVICE_TYPE) {
      if (this.occurs[currentFilter].filterArchived === '') {
        query['archived'] = false;
      }
      if (this.occurs[currentFilter].filterArchived === 'ARCHIVED') {
        query['archived'] = true;
      }
    }
    query.$limit = this.numberOfOccurrencesPerPage;
    query.$sort = `${sort}`;
    return query;
  }

  loadSingleOccurrence(occurrenceId) {
    if (occurrenceId) {
      this.singleOccurrenceLoadingStatus.setAsDownloading();
      this.isShowingSingleOccurrence = true;

      const query: EcondosQuery = {
        $populate: [
          { path: 'pictures', select: 'url thumbnail type format name videoThumbnail' },
          { path: 'comments' },
          {
            path: 'createdBy',
            select: 'firstName lastName picture',
            populate: [{ path: 'picture', select: 'url thumbnail type format name' }]
          },
          { path: 'viewers.residences', select: 'block address number identification type' },
          { path: 'viewers.users', select: 'firstName lastName' },
          { path: 'residence', select: 'address number identification type' },
          { path: 'userContacts', select: 'firstName lastName' },
          { path: 'likers', select: 'firstName lastName' },
          { path: 'subTypeAttachments', select: 'url thumbnail name type format name' },
          { path: 'updatedBy', select: 'firstName lastName' },
          { path: 'condoContacts', select: 'firstName lastName' },
          { path: 'groups', select: 'name' }
        ]
      };

      this.occurrenceService
        .getById(this.condo._id, occurrenceId, query)
        .pipe(timeout(10000))
        .subscribe(
          occurrence => {
            if (occurrence && occurrence.comments) {
              occurrence.comments.reverse();
            }
            this.occurrences = [occurrence];

            this.singleOccurrenceLoadingStatus.setAsSuccess();
          },
          (err: Error) => {
            if (err.originalErrorMessage.includes('Occurrence not found for id')) {
              this.singleOccurrenceLoadingStatus.setAsNotFound();
              return;
            }
            if (err.originalErrorMessage.includes('Occurrence is private for id')) {
              this.singleOccurrenceLoadingStatus.setAsNoAccess();
              return;
            }
            this.singleOccurrenceLoadingStatus.setAsError();
          }
        );
    }
  }

  loadData(event?) {
    if (event) {
      event.preventDefault();
    }
    const currentFilter = this.filterKey;
    this.occurs[currentFilter].status.setAsDownloading();
    this.getData(this.baseSuccessCallback, this.baseErrorCallback);
  }

  toggleFilter() {
    this.occurs[this.filterKey].showFilter = !this.occurs[this.filterKey].showFilter;
  }

  resetFilter() {
    this.occurs[this.filterKey].showFilter = false;
    this.occurs[this.filterKey].filterSearchTerm = '';
    this.occurs[this.filterKey].filterStatus = '';
    this.occurs[this.filterKey].filterStartDate = moment().startOf('year').toDate();
    this.occurs[this.filterKey].filterEndDate = moment().toDate();
    if (this.filterKey === Occurrence.PRIVATE_TYPE || this.filterKey === Occurrence.GATE_TYPE) {
      this.clearResidence();
    }
    if (this.filterKey === Occurrence.ADVICE_TYPE) {
      this.occurs[this.filterKey].filterArchived = '';
    }
    this.loadData();
  }

  loadAllOccurrences() {
    const currentFilter = this.filterKey;
    if (!this.occurs[currentFilter].status.isSuccess()) {
      this.occurs[currentFilter].status.setAsDownloading();
      this.getData(this.baseSuccessCallback, this.baseErrorCallback);
    }
  }

  getStatus(): Status {
    return this.occurs[this.filterKey].status;
  }

  goToSale() {
    let url = 'sale/';

    if (this.filterKey === Occurrence.VOTING_TYPE) {
      url += 'voting/';
    }
    if (this.filterKey === Occurrence.GATE_TYPE) {
      url += 'gate/';
    }

    this.router.navigate([url]);
  }

  setFilteredOccurrenceUrl(key) {
    this.router.navigate(['feed/' + key.toLowerCase()]);
  }

  setFilteredOccurrences(key) {
    this.filterKey = key;
    const currentFilter = this.filterKey;

    if (this.occurs[currentFilter].list.length > 0 || this.occurs[currentFilter].status.isSuccess()) {
      this.occurrences = this.occurs[currentFilter].list;
      return;
    }

    const successCallback = occurrences => {
      this.occurs[currentFilter].list = occurrences;
      if (currentFilter == this.filterKey) {
        this.occurrences = this.occurs[currentFilter].list;
      }
      this.occurs[currentFilter].status.setAsSuccess();
    };

    const errorCallback = () => {
      if (currentFilter == this.filterKey) {
        this.occurrences = this.occurs[currentFilter].list;
      }
      this.occurs[currentFilter].status.setAsError();
    };

    this.occurs[currentFilter].status.setAsDownloading();
    this.getData(successCallback, errorCallback);
  }

  getData(successCallback, errorCallback, pageNumber = 0, queryString: EcondosQuery = this.getBaseQueryString()) {
    const currentFilter = this.filterKey;
    let observable$: Observable<{ count: number; occurrences: Occurrence[] }>;
    if (pageNumber === 0) {
      this.occurs[currentFilter].pageNumber = 1;
    }
    queryString.$page = pageNumber;
    queryString.type = currentFilter;

    if (this.occurs[currentFilter].showFilter && this.occurs[currentFilter].filterSearchTerm) {
      queryString.$populate = [
        ...queryString.$populate,
        {
          path: 'comments',
          select: 'pictures createdAt createdBy description moderatedBy updatedAt',
          populate: [
            {
              path: 'createdBy',
              select: 'firstName lastName picture residencesUser residencesVoter',
              populate: [
                { path: 'picture', select: 'url thumbnail name' },
                {
                  path: 'residencesUser',
                  select: 'identification'
                },
                {
                  path: 'residencesVoter',
                  select: 'identification'
                }
              ]
            },
            {
              path: 'pictures',
              select: 'url thumbnail type name format'
            }
          ]
        }
      ];

      observable$ = this.occurrenceService.searchByToken(this.condo._id, this.occurs[currentFilter].filterSearchTerm, queryString);
    } else if (queryString.type === Occurrence.ADVICE_TYPE) {
      observable$ = this.occurrenceService.getAdvicesOccurrences(this.condo._id, queryString);
    } else {
      observable$ = this.occurrenceService.getOccurrences(this.condo._id, queryString);
    }
    observable$.pipe(timeout(10000)).subscribe({
      next: ({ occurrences }) => {
        // Usado para inverter a ordem dos comentários das ocorrências
        occurrences.forEach(occur => {
          if (occur?.comments) {
            occur.comments.reverse();
          }
        });

        this.occurs[currentFilter].hasMoreData = occurrences && occurrences.length == this.numberOfOccurrencesPerPage;

        successCallback(occurrences);
      },
      error: err => {
        errorCallback(err);
      }
    });
  }

  hasResidence() {
    return this.userResidences.length;
  }

  deleteOccurrence(occurrence) {
    const that = this;
    const occurrenceType = Occurrence.TYPES_LABEL[occurrence.type];
    swal({
      type: 'question',
      title: 'Apagar ' + occurrenceType,
      text: 'Deseja realmente apagar este item?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return that.occurrenceService
          .deleteOccurrence(this.condo._id, occurrence._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível apagar este item, verifique sua conexão e tente novamente...');
          });
      }
    }).then(
      success => {
        console.log(success);
        that.occurrences.splice(this.occurrences.indexOf(occurrence), 1);
        this.toastr.success('Registro apagado com sucesso');
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  enableCommentsOnOccurrence(occurrence) {
    const that = this;
    swal({
      type: 'question',
      text: 'Deseja liberar os comentários desta ocorrência?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return that.occurrenceService
          .enableCommentsOnOccurrence(this.condo._id, occurrence._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível liberar os comentários desta ocorrência, tente novamente...');
          });
      }
    }).then(
      () => {
        occurrence.params = {
          commentsModeration: 'NONE'
        };
        this.toastr.success('Comentários liberados com sucesso');
        this.onOccurrenceUpdated(occurrence);
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  disableCommentsOnOccurrence(occurrence) {
    const that = this;
    swal({
      type: 'question',
      text: 'Deseja bloquear os comentários desta ocorrência?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return that.occurrenceService
          .disableCommentsOnOccurrence(this.condo._id, occurrence._id)
          .pipe(timeout(10000))
          .toPromise()
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível bloquear os comentários desta ocorrência, tente novamente...');
          });
      }
    }).then(
      () => {
        occurrence.params = {
          commentsModeration: 'REQUIRED'
        };
        this.onOccurrenceUpdated(occurrence);
        this.toastr.success('Comentários bloqueados com sucesso');
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  onOccurrenceCreated(occurrence: Occurrence) {
    if (!occurrence.isVotingType()) {
      const currentFilter = this.filterKey;
      this.occurs[currentFilter].status.setAsDownloading();
      this.getData(this.baseSuccessCallback, this.baseErrorCallback);
    } else {
      // Necessário para atualizar as alternativas da enquete.
      const currentFilter = this.filterKey;
      this.occurs[currentFilter].status.setAsDownloading();
      const qs = [];
      qs[qs.length] = '$populate[0]=pictures';
      qs[qs.length] = '$populate[1]=comments';
      qs[qs.length] = '$populate[2][path]=createdBy';
      qs[qs.length] = '$populate[2][select]=firstName lastName picture';
      qs[qs.length] = '$populate[2][populate][path]=picture';
      qs[qs.length] = '$populate[2][populate][select]=url thumbnail';
      qs[qs.length] = '$populate[3]=viewers.residences';
      qs[qs.length] = '$populate[4]=residence';
      this.occurrenceService
        .getOccurrenceById(this.condo._id, occurrence.id, '?' + qs.join('&'))
        .pipe(timeout(10000))
        .subscribe(
          occur => {
            this.occurrences.unshift(occur);
            this.occurs[currentFilter].status.setAsSuccess();
          },
          () => {
            this.occurrences.unshift(occurrence);
            this.occurs[currentFilter].status.setAsError();
          }
        );
    }
  }

  onOccurrenceUpdated(occurrence) {
    const index = this.occurrences.findIndex(occ => occ._id === occurrence._id);
    if (index !== -1) {
      this.occurrences[index] = OccurrenceBuilder.build(occurrence);
    }
  }

  editOccurrence(occurrence: Occurrence) {
    if (occurrence.isAdviceType()) {
      this.adviceCreatorComponent.editOccurrence(occurrence);
    } else if (occurrence.isPrivateType()) {
      this.privateCreatorComponent.editOccurrence(occurrence);
    } else if (occurrence.isVotingType()) {
      this.votingCreatorComponent.editOccurrence(occurrence);
    } else if (occurrence.isGateType()) {
      const initialState = {
        condo: this.condo,
        occurrence: occurrence as GateOccurrence,
        callback: occurrence => {
          this.onOccurrenceUpdated(occurrence);
        }
      };
      this.modalService.show(ModalCreateAuthorizedPersonComponent, {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-lg'
      });
    }
  }

  showImagesViewer(pictures, index) {
    this.lightboxModal.showImagesViewer(pictures, index);
  }

  onInfinite() {
    if (this.isInfiniting) {
      return;
    }

    this.isInfiniting = true;
    const currentFilter = this.filterKey;

    const successCallback = occurrences => {
      const temp = [];

      occurrences.forEach(occurrence => {
        temp.push(occurrence);
      });

      this.occurs[currentFilter].list = [].concat(this.occurs[currentFilter].list, temp);

      if (occurrences && occurrences.length != 0) {
        this.occurs[currentFilter].pageNumber++;
      }
      this.occurrences = this.occurs[currentFilter].list;

      this.isInfiniting = false;
    };

    const errorCallback = () => {
      this.isInfiniting = false;
    };
    this.getData(successCallback, errorCallback, this.occurs[currentFilter].pageNumber);
  }

  askToCloseOccurrence(occurrence) {
    swal({
      title: 'Encerrar ocorrência',
      type: 'question',
      text: 'Digite abaixo os detalhes',
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Concluir',
      confirmButtonColor: '#32DB64',
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: closingDetails => {
        return this.occurrenceService
          .updateOcurrence(this.condo.id, occurrence.id, {
            closingDetails: closingDetails,
            status: Occurrence.STATUS.CLOSED
          })
          .pipe(timeout(10000))
          .toPromise()
          .then(() => {
            const updatedOccurrence = this.occurrences.find(occ => occurrence.id == occ.id);
            updatedOccurrence.status = Occurrence.STATUS.CLOSED;
            updatedOccurrence.closingDetails = closingDetails;
            updatedOccurrence.updatedBy = this.user;
            updatedOccurrence.updatedAt = new Date().toISOString();
          })
          .catch(err => {
            console.log(err);
            return Promise.reject('Não foi possível encerrar esta ocorrência, tente novamente.');
          });
      }
    }).then(
      () => {
        this.toastr.success('Ocorrência encerrada com sucesso');
        this.onOccurrenceUpdated(occurrence);
      },
      () => {
        console.log('Clicked cancel');
      }
    );
  }

  initializeTypeAheads() {
    this.residenceTypeAheadDataSource$ = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.residenceSearch);
    }).pipe(
      distinctUntilChanged(),
      filter((text: string) => !!(text || '').trim()),
      mergeMap((token: string) => {
        const query: EcondosQuery = {
          $select: 'block identification lot number address type'
        };

        return this.residenceService.searchByToken(this.condo._id, token, query).pipe(
          map(res => res.residences),
          catchError(() => {
            this.residenceTypeAheadStatus.setAsError();
            return of([]);
          })
        );
      })
    );
  }

  onResidenceSelect(event) {
    const residence: Residence = event.item;
    this.occurs[this.filterKey].filterResidence = residence;
  }

  clearResidence() {
    this.residenceSearch = '';
    this.occurs[this.filterKey].filterResidence = null;
  }

  goToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  pin(advice) {
    const occurrence: any = {
      ...advice,
      pin: true
    };
    const occurrenceId = advice._id;

    this.occurrenceService
      .updateOcurrence(this.condo._id, occurrenceId, occurrence)
      .pipe(timeout(10000))
      .subscribe({
        next: () => {
          const currentFilter = this.filterKey;
          this.occurs[currentFilter].status.setAsDownloading();
          this.getData(this.baseSuccessCallback, this.baseErrorCallback);
        },
        error: () => {
          swal({
            text: 'Não foi possível afixar o aviso.'
          });
        }
      });
  }

  unpin(advice) {
    const occurrence: any = {
      ...advice,
      pin: false
    };
    const occurrenceId = advice._id;
    this.occurrenceService
      .updateOcurrence(this.condo._id, occurrenceId, occurrence)
      .pipe(timeout(10000))
      .subscribe({
        next: () => {
          const currentFilter = this.filterKey;
          this.occurs[currentFilter].status.setAsDownloading();
          this.getData(this.baseSuccessCallback, this.baseErrorCallback);
        },
        error: () => {
          swal({
            text: 'Não foi possível afixar o aviso.'
          });
        }
      });
  }

  archive(advice) {
    const occurrence: any = {
      ...advice,
      archived: true
    };
    const currentFilter = this.filterKey;
    const occurrenceId = advice._id;
    this.occurrenceService
      .updateOcurrence(this.condo._id, occurrenceId, occurrence)
      .pipe(timeout(10000))
      .subscribe({
        next: () => {
          const index = this.occurrences.findIndex(a => a._id === occurrenceId);
          if (index >= 0) {
            if (!this.occurs[currentFilter].filterArchived) {
              this.occurrences.splice(index, 1);
            } else {
              this.occurrences.splice(index, 1, OccurrenceBuilder.build(occurrence));
            }
          }
        },
        error: () => {
          swal({
            text: 'Não foi possível afixar o aviso.'
          });
        }
      });
  }

  unarchive(advice) {
    const occurrence: any = {
      ...advice,
      archived: false
    };
    const currentFilter = this.filterKey;
    const occurrenceId = advice._id;
    this.occurrenceService
      .updateOcurrence(this.condo._id, occurrenceId, occurrence)
      .pipe(timeout(10000))
      .subscribe({
        next: () => {
          const index = this.occurrences.findIndex(a => a._id === occurrenceId);
          if (index >= 0) {
            if (this.occurs[currentFilter].filterArchived === 'ARCHIVED') {
              this.occurrences.splice(index, 1);
            } else {
              this.occurrences.splice(index, 1, OccurrenceBuilder.build(occurrence));
            }
          }
        },
        error: () => {
          swal({
            text: 'Não foi possível afixar o aviso.'
          });
        }
      });
  }

  openLiberationModal() {
    const initialState = {
      condo: this.condo,
      callback: liberation => {
        this.loadData();
        if (!liberation?.condoContacts?.length && this.canSelfRegistrate) {
          const link = createLinkToShareOccurrence(this.condo._id, liberation._id, this.themeService.activeTheme);
          this.modalService.show(ModalShareLinkComponent, {
            initialState: { link },
            ignoreBackdropClick: true,
            class: 'modal-lg'
          });
        }
      }
    };
    this.modalService.show(ModalCreateAuthorizedPersonComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });
  }
}
