import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as qs from 'qs';
import { EcondosQuery } from '../model/query';
import { AccessGroup } from '@api/model/hardware/access-group';
import { ActiveVisitor, buildActiveVisitor } from '@api/model/active-visitor';

@Injectable({ providedIn: 'root' })
export class AccessGroupService {
  private endPoint;

  constructor(
    private http: HttpService,
    private constantService: ConstantService
  ) {
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  get(condoId: string, params: EcondosQuery = {}): Observable<{ count: number; accessGroups: AccessGroup[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}${condoId}/access-group`, {
        params: httpParams
      })
      .pipe(
        map((res: any) => ({
          count: res.headers.get('count'),
          accessGroups: res.body
        }))
      );
  }

  getWithToken(condoId: string, query: EcondosQuery, token = ''): Observable<{ count: number; accessGroups: AccessGroup[] }> {
    query.token = token;
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = {
      headers: new HttpHeaders(),
      params,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/access-group/searchToken`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        accessGroups: res.body
      }))
    );
  }

  create(condoId: string, accessGroup): Observable<AccessGroup> {
    return this.http.post(`${this.endPoint}${condoId}/access-group`, accessGroup).pipe(
      map((data: { _id: string }) => ({
        _id: data._id,
        name: accessGroup.name,
        actuators: accessGroup.actuators,
        createdAt: new Date().toISOString(),
        intelbrasGroupId: accessGroup.intelbrasGroupId
      }))
    );
  }

  update(condoId: string, accessGroupId: string, accessGroup: AccessGroup) {
    return this.http.put(`${this.endPoint}${condoId}/access-group/${accessGroupId}`, accessGroup);
  }

  delete(condoId: string, accessGroupId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/access-group/${accessGroupId}`);
  }

  getById(condoId: string, accessGroupId: string, query: EcondosQuery = {}): Observable<AccessGroup> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.get(`${this.endPoint}${condoId}/access-group/${accessGroupId}`, { params }) as Observable<AccessGroup>;
  }

  bulkAddToDevices(condoId: string, accessGroupId: string, devices: string[]) {
    return this.http.put(`${this.endPoint}${condoId}/access-group/bulk-add/${accessGroupId}`, { devices });
  }

  bulkRemoveFromDevices(condoId: string, accessGroupId: string, devices: string[]) {
    return this.http.put(`${this.endPoint}${condoId}/access-group/bulk-remove/${accessGroupId}`, { devices });
  }
}
