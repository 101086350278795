import { Residence } from '@api/model/interface/residence';
import { File } from '@api/model/file';
import { User } from '@api/model/user';
import { Condo } from '@api/model/condo';
import { ResidenceBuilder } from '@api/model/residence/residence.builder';
import { capitalize } from 'lodash';
import { CondoUser } from '@api/model/condo-user';

export const CONDO_REQUEST_ROLES = {
  ADMIN: 'ADMIN',
  GATEKEEPER: 'GATEKEEPER',
  JANITOR: 'JANITOR',
  RESIDENT: 'RESIDENT',
  VOTER: 'VOTER',
  VOTER_AND_RESIDENT: 'VOTER_AND_RESIDENT'
} as const;

export const CONDO_REQUEST_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
} as const;

export interface CondoRequest {
  condo?: Condo;
  _id?: string;
  user: User;
  residence?: Residence;
  attachments: File[];
  obs: string;
  role?: keyof typeof CONDO_REQUEST_ROLES;
  status: keyof typeof CONDO_REQUEST_STATUS;
  verifiedAt?: string;
  verifiedBy?: CondoUser;
  createdAt?: string;
}

export const BuildCondoRequest = (data): CondoRequest => {
  return {
    _id: typeof data === 'string' ? data : data._id,
    condo: new Condo(data.condo),
    user: data.user ? new User(data.user) : null,
    attachments: (data.attachments || []).map(f => new File(f)),
    residence: data.residence ? ResidenceBuilder.build(data.residence) : null,
    obs: data.obs || '',
    role: data.role || null,
    status: data.status || null,
    verifiedAt: data.verifiedAt || null,
    verifiedBy: data.verifiedBy || null,
    createdAt: data.createdAt || ''
  };
};

export const getRequestRolesLabels = (condo: Condo) => ({
  VOTER_AND_RESIDENT: `${capitalize(condo.customLabels.voter.singular) || 'Proprietário'} e ${condo.customLabels.resident.singular}`,
  VOTER: `${capitalize(condo.customLabels.voter.singular) || 'Proprietário'} somente`,
  RESIDENT: `${capitalize(condo.customLabels.resident.singular)} somente`
});
