import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { File } from '@api/model/file';
import { FileService } from '@api/service/file.service';
import { UploadButton } from '../../../components/upload-button';
import { LightboxComponent } from '../../../components/lightbox/lightbox';
import { Condo } from '@api/model/condo';
import { CondoFilesService } from '@api/serviceV2/condo.files.service';

@Component({
  selector: 'files-scroll',
  templateUrl: 'files.scroll.html'
})
export class OccurrenceFileUploader {
  @ViewChild(UploadButton, { static: true }) uploadButton: UploadButton;
  @ViewChild('filesList', { static: true }) filesUL;
  @ViewChild(LightboxComponent, { static: true }) lightboxModal: LightboxComponent;

  @Input() public useLightboxViewer = false;

  @Input()
  public canRemoveFile = true;
  @Input()
  public canAddFile = true;
  @Input()
  public fileTypes = '*';
  @Input()
  public files: Array<File>;
  @Input()
  public condo: Condo = null;

  @Input()
  public dataTestIdIndex: number | string;

  public uploadingFiles: Array<any> = new Array<any>();

  @Output()
  onUploadStarted = new EventEmitter();

  @Output()
  onUploadCompleted = new EventEmitter();

  @Output() removeClick: EventEmitter<File> = new EventEmitter<File>();

  uploadFileCallback;

  constructor(
    private fileService: FileService,
    private condoFilesService: CondoFilesService
  ) {
    this.uploadFileCallback = files => {
      this.uploadFiles(files);
    };
  }

  addFile(file: File) {
    this.files.push(file);
  }

  addUploadingFile() {
    this.uploadingFiles.push({});
  }

  completeUploadingFile() {
    this.uploadingFiles.pop();
  }

  completeUploadingFiles() {
    this.uploadingFiles = [];
  }

  removeFile(index?) {
    if (index || index == 0) {
      this.removeClick.emit(this.files[index]);
      this.files.splice(index, 1);
    } else {
      this.files.pop();
    }
  }

  pickFile() {
    this.uploadButton.triggerClick();
  }

  pickFileSound() {
    this.fileTypes = ' audio/*';
    this.uploadButton.triggerClick();
  }

  pickFileImage() {
    this.fileTypes = ' image/*, video/*';
    this.uploadButton.triggerClick();
  }

  scrollToRIght(value) {
    const offset = 90;
    const container = this.filesUL.nativeElement;
    container.scrollLeft += value + offset;
  }

  uploadFiles(files) {
    this.onUploadStarted.emit();
    const maxFileSize = 1024 * 30 * 1000;

    const filesArray = Array.from(files);
    const bigFiles = filesArray.filter((f: { size: number; name: string }) => f.size > maxFileSize);
    if (bigFiles.length) {
      const bigFilesName = bigFiles.map((f: { size: number; name: string }) => f.name).join(', ');
      swal({
        type: 'error',
        title: 'Tamanho máximo de arquivo excedido',
        text: `O tamanho máximo de um arquivo válido são 30MB, os seguintes arquivos são maiores que isso: ${bigFilesName}`
      });
      this.onUploadCompleted.emit();
      return;
    }

    const successCallback = response => {
      if (response.length) {
        for (let i = 0; i < response.length; i++) {
          this.completeUploadingFile();
          this.addFile(response[i]);
        }
      }

      if (!this.uploadingFiles.length) {
        this.onUploadCompleted.emit(response);
      }

      setTimeout(() => {
        this.scrollToRIght(response.length * 90);
      }, 1000);
    };

    const errorCallback = err => {
      for (let i = 0; i < files.length; i++) {
        this.completeUploadingFile();
      }

      if (!this.uploadingFiles.length) {
        this.onUploadCompleted.emit();
      }

      // TODO tratar erro
      swal({
        type: 'error',
        title: `Ops...`,
        text: 'Não foi efetuar o upload de um ou mais arquivos, tente novamente',
        cancelButtonColor: '#f53d3d'
      });
    };

    for (const f of files) {
      const formData = new FormData();
      formData.append(f.title || 'file', f);
      this.addUploadingFile();
      setTimeout(() => {
        this.scrollToRIght(files.length * 90);
      }, 100);

      if (this.condo) {
        this.condoFilesService.uploadFilesFromFormData(this.condo._id, formData).subscribe(successCallback, errorCallback);
      } else {
        this.fileService.uploadFilesFromFormData(formData).subscribe(successCallback, errorCallback);
      }
    }
  }

  showImagesViewer(pictures, index, url) {
    if (this.useLightboxViewer) {
      this.lightboxModal.showImagesViewer(pictures, index);
    } else {
      window.open(url, '_blank');
    }
  }
}
