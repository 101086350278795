import { Injectable } from '@angular/core';
import { Actuator } from '@api/model/hardware/actuator';
import { defer, forkJoin, from, merge, Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { BravasSDK } from '@econdos/econdos-bravas/dist/modulo-acesso';
import { HikvisionSDK } from '@econdos/econdos-hikvision-v2';
import { XPESDK } from '@econdos/econdos-xpe';
import { AccessGroup } from '@api/model/hardware/access-group';
import { Device } from '@api/model/hardware/device';
import { BaseSDK, Capabilities, CaptureFingerprintOptions, EcondosDevice } from '@econdos/econdos-base-sdk';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import swal from 'sweetalert2';
import { defaultCapabilities } from '@econdos/econdos-base-sdk/dist/capabilities';
// @ts-ignore
import { Mip1000IPWebSDK } from '@econdos/econdos-mip1000/web';
import { AvicamSDK } from '@econdos/econdos-avicam';

export type UpsertDeviceResponse = {
  success: boolean;
  actuator: Actuator;
  data?: EcondosDevice;
  error?: any;
};

const sdks = {
  [HARDWARES.BRAVAS]: BravasSDK,
  [HARDWARES.HIKVISION]: HikvisionSDK,
  [HARDWARES.XPE]: XPESDK,
  [HARDWARES.MIP1000IP]: Mip1000IPWebSDK,
  [HARDWARES.AVICAM]: AvicamSDK
};

export type ResponseResidence = {
  _id: string;
  identification: string;
  lot?: string;
  block?: string;
  parkingSpaces?: number;
  parkingUsage?: number;
  status: boolean;
};

@Injectable({ providedIn: 'root' })
export class SdkService {
  // Variável utilizada como Singleton para instâncias da classe BravasSDK
  private sdkActuators = {};

  static getNewDefaultCapabilities = (): Capabilities => {
    return JSON.parse(JSON.stringify(defaultCapabilities));
  };

  static getCapabilities(hardwares: string[]): Capabilities {
    // @ts-ignore
    const capabilities = hardwares.map(h => SdkService.getCapabilitiesByHardware(h));
    return this.mergeCapabilities(capabilities);
  }

  static mergeCapabilities(capabilities: Capabilities[]): Capabilities {
    const cap = SdkService.getNewDefaultCapabilities();
    const result: Capabilities = capabilities.reduce((acc, curr) => {
      acc = mergeData(acc, curr);
      return acc;
    }, cap);

    return result;
  }

  static getCapabilitiesByHardware(hardware: string): Capabilities {
    const Sdk = sdks[hardware];
    if (!Sdk) {
      return this.getNewDefaultCapabilities();
    }
    return Sdk.getCapabilities();
  }

  constructor() {}

  public createSDK(actuator: Actuator): BaseSDK {
    const Sdk = sdks[actuator.hardware];
    if (!Sdk) {
      throw new Error(`SDK not found for hardware ${actuator.hardware}`);
    }
    return new Sdk(actuator);
  }

  clearDeviceCache(actuatorId?: string) {
    if (actuatorId) {
      delete this.sdkActuators[actuatorId];
    } else {
      this.sdkActuators = {};
    }
  }

  public getSDKs(actuators: Actuator | Actuator[]) {
    if (!Array.isArray(actuators)) {
      actuators = [actuators];
    }
    return actuators.map(a => this.getSDK(a));
  }

  public getSDK(actuator: Actuator): BaseSDK {
    if (!this.sdkActuators[actuator._id]) {
      // @ts-ignore
      this.sdkActuators[actuator._id] = this.createSDK(actuator);
    }
    return this.sdkActuators[actuator._id];
  }

  trigger(actuator: Actuator, params: { door?: number; relayTime?: number; direction?: 'ENTRANCE' | 'EXIT' } = {}) {
    const sdkInstance = this.getSDK(actuator);
    return from(sdkInstance.openDoor(params));
  }

  lock(actuator: Actuator) {
    const sdkInstance = this.getSDK(actuator);
    return from(sdkInstance.lockDoor({}));
  }

  unlock(actuator: Actuator) {
    const sdkInstance = this.getSDK(actuator);
    return from(sdkInstance.unlockDoor({}));
  }

  checkConnection(actuator: Actuator) {
    const sdkInstance = this.getSDK(actuator);
    return from(sdkInstance.checkConnection()).pipe(
      timeout(10000),
      map(() => ({ _id: actuator._id, status: true })),
      catchError(() => of({ _id: actuator._id, status: false }))
    );
  }

  configure(actuator: Actuator) {
    const sdkInstance = this.getSDK(actuator);
    return from(sdkInstance.configure());
  }

  configureActuator(actuator: Actuator) {
    const sdkInstance = this.getSDK(actuator);
    // @ts-ignore
    return from(sdkInstance.configure({ configureMonitor: false }));
  }

  addAccessGroup(accessGroup: AccessGroup) {
    return this.upsertAccessGroup(accessGroup);
  }

  updateAccessGroup(accessGroup: AccessGroup) {
    return this.upsertAccessGroup(accessGroup);
  }

  upsertAccessGroup(accessGroup: AccessGroup) {
    const accessGroupsByDevices = this.separateAccessGroupsByDevices(accessGroup);
    const requests = [];
    for (const accessGroupByDevice of accessGroupsByDevices) {
      const actuator = accessGroupByDevice.actuators[0];
      const sdk = this.getSDK(actuator);
      // @ts-ignore
      const observable = from(sdk.saveAccessGroup(accessGroupByDevice));
      requests.push(observable);
    }
    return forkJoin(requests);
  }

  private separateAccessGroupsByDevices(accessGroup: AccessGroup) {
    const actuatorsByDeviceObj = accessGroup.actuators.reduce((acc, actuator) => {
      const deviceKey = `${actuator.host || actuator.host2}:${actuator.port || actuator.port2}`;
      if (acc[deviceKey]) {
        acc[deviceKey].push(actuator);
      } else {
        acc[deviceKey] = [actuator];
      }
      return acc;
    }, {});

    const actuatorsByDevice = Object.values(actuatorsByDeviceObj);
    const accessGroupsByDevice = actuatorsByDevice.map(actuators => ({ ...accessGroup, actuators }));
    return accessGroupsByDevice;
  }

  addDevice(device: Device, actuators?: Actuator[]) {
    return this.upsertDevice(device, actuators);
  }

  updateDevice(device: Device) {
    return this.upsertDevice(device);
  }

  upsertDevice(device: Device, actuators?: Actuator[]): Observable<UpsertDeviceResponse>[] {
    const uniqueActuators = actuators || this.getUniqueActuatorsFromDevice(device);
    if (uniqueActuators.length > 0) {
      const observables = uniqueActuators.map(actuator => {
        const sdkInstance = this.getSDK(actuator);
        const observable = defer(() =>
          sdkInstance
            // @ts-ignore
            .saveDevice(device)
            .then(res => ({ ...res, actuator }))
            .catch(e => ({
              success: false,
              actuator,
              error: e
            }))
        );
        return observable;
      });

      if (device.type === 'FACIAL' || device.type === 'BM') {
        const statusObservables = Object.values(uniqueActuators)
          .map(actuator => {
            const sdkInstance = this.getSDK(actuator);
            if (sdkInstance instanceof BravasSDK) {
              const observable = defer(() => sdkInstance.getAsyncStatus(30));
              return observable;
            }
          })
          .filter(observable => observable);

        let errors = [];

        if (statusObservables?.length) {
          merge(...statusObservables).subscribe(
            (res: any) => {
              errors = errors.concat(res.errors || []);
            },
            () => {},
            () => {
              if (errors.length) {
                const errorsMessage = errors.map((e, i) => `<div>${i + 1} - ${e.actuator}: ${e.errors.join(', ')}</div>`).join('');
                swal({
                  type: 'error',
                  title: 'Falha de sincronização',
                  html: `
                    <div>Não foi possível sincronizar nos seguintes equipamentos:</div>
                    ${errorsMessage}
                  `
                });
              }
            }
          );
        }
      }
      return observables;
    } else {
      return [];
    }
  }

  deleteDevice(device: Device, actuators?: Actuator[]): Observable<any>[] {
    const uniqueActuators = actuators || this.getUniqueActuatorsFromDevice(device);
    if (uniqueActuators.length) {
      const observables = uniqueActuators.map(actuator => {
        const sdkInstance = this.getSDK(actuator);
        const observable = defer(() =>
          sdkInstance
            // @ts-ignore
            .deleteDevice(device)
            .then(res => ({ ...res, actuator }))
            .catch(e => ({
              success: false,
              actuator,
              error: e
            }))
        );
        return observable;
      });
      return observables;
    } else {
      return [];
    }
  }

  private getBravasActuatorsFromDevice(device: Device): Actuator[] {
    // Agrupa todos acionadores que existem no dispositivo
    const allActuators = device.accessGroups.reduce((acc, curr) => {
      acc = acc.concat(...curr.actuators);
      return acc;
    }, []);
    // Filtra todos que são BRAVAS
    const bravasActuators = allActuators.filter(a => a.hardware === HARDWARES.BRAVAS);
    return bravasActuators;
  }

  private getUniqueActuatorsFromDevice(device: Device): Actuator[] {
    const allActuators: Actuator[] = [];
    for (const accessGroup of device.accessGroups) {
      allActuators.push(...accessGroup.actuators);
    }
    const uniqueDevicesByActuator = allActuators.reduce((acc, actuator) => {
      const deviceKey = `${actuator.host || actuator.host2}:${actuator.port || actuator.port2}`;
      if (!acc[deviceKey]) {
        acc[deviceKey] = actuator;
      }
      return acc;
    }, {});
    return Object.values(uniqueDevicesByActuator);
  }

  getUniqueActuators(actuators: Actuator[]): Actuator[] {
    const uniqueDevicesByActuator = actuators.reduce((acc, actuator) => {
      const deviceKey = `${actuator.host || actuator.host2}:${actuator.port || actuator.port2}`;
      if (!acc[deviceKey]) {
        acc[deviceKey] = actuator;
      }
      return acc;
    }, {});
    return Object.values(uniqueDevicesByActuator);
  }

  exportActuators({ host, port, password }) {
    const sdk = new BravasSDK({ host, port, password } as any);
    return sdk.exportActuators();
  }

  exportAccessGroups(actuator: Actuator) {
    const sdkInstance = this.getSDK(actuator);
    return sdkInstance.exportAccessGroups();
  }

  generateQrcode(hardware: HARDWARES = HARDWARES.HIKVISION): Promise<string> {
    const sdkIstance = sdks[hardware];
    return sdkIstance.generateQrcode();
  }

  captureFingerprint({
    actuator,
    finger,
    timeout = 1000 * 60 * 2 // 2 minutos
  }: {
    actuator: Actuator;
    finger: CaptureFingerprintOptions['finger'];
    timeout?: number;
  }) {
    const Sdk = this.getSDK(actuator);
    return Sdk.captureFingerprint({ finger, timeout, actuatorName: actuator.name });
  }

  captureFaceBase64({
    actuator,
    timeout = 1000 * 60 * 2 // 2 minutos
  }: {
    actuator: Actuator;
    timeout?: number;
  }) {
    const Sdk = this.getSDK(actuator);
    return Sdk.captureFace({ actuatorName: actuator.name, timeout, base64: true });
  }

  setDatetime(actuator, dateIsoString?: string, timeZone?: string) {
    const sdkInstance = this.getSDK(actuator);
    return from(sdkInstance.setDatetime(dateIsoString, timeZone));
  }

  syncDateTime(actuator: Actuator) {
    const sdkInstance = this.getSDK(actuator);

    return from(sdkInstance.syncDatetime());
  }

  /**
   *
   * @param actuator
   * @param residence Residência contendo obrigatóriamente o ID e o identification, opcionalmente lot,block,parkingSpaces e parkingUsage
   * @return success: boolean
   */
  saveResidence(actuator: Actuator, residence: ResponseResidence): Observable<any> {
    const sdk = this.getSDK(actuator);
    return defer(() => from(sdk.saveResidence(residence)));
  }

  /**
   *
   * @param actuator
   * @param id id da residencia
   * @return success: boolean
   */
  deleteResidence(actuator: Actuator, residence: ResponseResidence): Observable<any> {
    const sdk = this.getSDK(actuator);
    return defer(() => from(sdk.deleteResidence(residence)));
  }

  /**
   * Retorna uma unidade especifica
   * @param actuator
   * @param residence Residência contendo obrigatóriamente o ID para busca por id, ou identification,bloco e/ou lot para busca sem id
   * @param searchById Se true, busca por id, caso contrário busca por identification,bloco e/ou lot
   * @param userList Se true, retorna a lista de usuarios associados a essa unidade
   * @param userListUuid Se true, retorna a lista de ids de usuarios associados a essa unidade
   * @return success: boolean,data: {
   *  "garage_size": 1,
   *  "garage_usage": 0,
   *  "uuid": "",
   *  "add1": "",
   *  "add2": "",
   *  "add3": "",
   *  "add4": "",
   *  "user_list": [
   * namelist or idlist
   *  ],
   *  "action": "getUnit"
   *  }
   */
  getSingleResidence(
    actuator: Actuator,
    residence: ResponseResidence,
    searchById = true,
    userList = true,
    userListUuid = false
  ): Observable<any> {
    const sdk = this.getSDK(actuator);
    return from(sdk.getSingleResidence(residence, searchById, userList, userListUuid));
  }

  /**
   *
   * @param actuator
   * @returns success: boolean,data:{"units": [{
   *  "garage_size": 1,
   *  "garage_usage": 0,
   *  "uuid": "",
   *  "add1": "",
   *  "add2": "",
   *  "add3": "",
   *  "add4": ""
   *  }],
   */
  getAllResidences(actuator: Actuator): Observable<any> {
    const sdk = this.getSDK(actuator);
    return from(sdk.getAllResidences());
  }

  /**
   *@param actuator
   *@param id id da unidade
   * @returns success: boolean,data:{"garage_usage": x,
   *  "uuid": "x",
   *  "action": "getGarageUsage"}
   */
  getParkingInfo(actuator: Actuator, residence: ResponseResidence): Observable<any> {
    const sdk = this.getSDK(actuator);
    return from(sdk.getParkingInfo(residence));
  }

  reboot(actuator: Actuator, username: string = '') {
    const sdkInstance = this.getSDK(actuator);
    return from(sdkInstance.reboot(username)).pipe(
      timeout(10000),
      catchError(() => of({ success: false }))
    );
  }

  disableUploadPicture(actuator: Actuator) {
    const sdkInstance = this.getSDK(actuator);
    return from(sdkInstance.disableUploadPicture()).pipe(
      timeout(10_000),
      catchError(error => of({ success: false, error }))
    );
  }

  exportDevices(
    actuator: Actuator,
    deviceType?: EcondosDevice['type'],
    accessType?: 'RESIDENT' | 'VISITOR'
  ): Observable<{
    data: EcondosDevice[];
    success: boolean;
  }> {
    const sdkInstance = this.getSDK(actuator);
    return from(sdkInstance.exportDevices(deviceType, accessType)).pipe(
      map(response => {
        const devices: EcondosDevice[] = response.actuators || [];
        return {
          data: devices,
          success: response.success
        };
      })
    );
  }
}

const mergeData = (d1, d2) => {
  const keysValues = Object.entries(d1);
  const result: any = {};
  keysValues.forEach(([key, value1]) => {
    const value2 = d2[key];
    if (typeof value1 === 'object') {
      result[key] = mergeData(value1, value2);
    } else if (typeof value1 === 'boolean') {
      result[key] = value1 || value2;
    }
  });
  return result;
};
