import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MARITAL_STATUS_LABEL } from '@api/model/constants';
import { User } from '@api/model/user';
import { emptyOrValidCnpjValidator, conditionalValidator, cpfValidator, fullNameValidator, personBirthDateValidator } from '@api/util/validators';
import { formatCnpj, formatCpf, formatPhone } from '@api/util/formatters';
import { ContactID } from '@api/model/contact/contact.id';
import { Error } from '@api/model/error/error';
import { debounceTime, filter, map, mergeMap, timeout } from 'rxjs/operators';
import { CondoService } from '@api/service/condo.service';
import swal from 'sweetalert2';
import { lastValueFrom, Subscription } from 'rxjs';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { EcondosQuery } from '@api/model/query';
import { Condo } from '@api/model/condo';
import { UtilService } from 'app/services/util.service';
import { AsyncValidators } from '@api/util/async-validators';

@Component({
  selector: 'step-user',
  templateUrl: 'step-user.html',
  styleUrls: ['step-user.scss']
})
export class StepUserComponent implements OnInit, OnDestroy {
  @Input()
  globals: any;

  @Input()
  index: number;

  @Output()
  formIsValid = new EventEmitter<boolean>();

  @Output()
  saveUser = new EventEmitter<User>();

  STATUS = {
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS'
  };
  condo: Condo;
  user: User;
  status;

  form: UntypedFormGroup;
  picture: AbstractControl;
  email: AbstractControl;
  name: AbstractControl;
  birthDate: AbstractControl;
  cpf: AbstractControl;
  cnpj: AbstractControl;
  rg: AbstractControl;
  passport: AbstractControl;
  ra: AbstractControl;
  phone: AbstractControl;
  phone2: AbstractControl;
  phone3: AbstractControl;
  occupation: AbstractControl;
  maritalStatus: AbstractControl;
  specialNeeds: AbstractControl;
  specialNeedsDetails: AbstractControl;

  MARITAL_STATUS = MARITAL_STATUS_LABEL;

  private subscriptions: Subscription = new Subscription();

  saveUserButtonAndLabelDisabled = true;

  editField = {
    email: false,
    cpf: false,
    cnpj: false,
    ra: false,
    rg: false,
    passport: false,
    phones: false,
    birthDate: false
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private condoService: CondoService,
    private toastrService: ToastrService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.condo = this.utilService.getLocalCondo();
    this.user = this.utilService.getLocalUser();
    this.initializeForm();
    if (this.globals.user) {
      this.setEntireForm();
    } else {
      this.form.addControl('email', this.email);
      this.form.addControl('cpf', this.cpf);
      this.form.addControl('cnpj', this.cnpj);
      this.form.addControl('passport', this.passport);
      this.form.addControl('ra', this.ra);
      this.form.addControl('phone', this.phone);
      this.form.addControl('phone2', this.phone2);
      this.form.addControl('phone3', this.phone3);
      this.form.addControl('birthDate', this.birthDate);
      this.form.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, fullNameValidator])],
      picture: [null],
      rg: [''],
      cpf: [''],
      cnpj: [''],
      passport: [''],
      ra: [''],
      occupation: [''],
      maritalStatus: [''],
      specialNeeds: [false],
      specialNeedsDetails: ['']
    });

    this.name = this.form.get('name');
    this.picture = this.form.get('picture');
    this.email = new UntypedFormControl('', [Validators.compose([Validators.email])]);
    this.birthDate = new UntypedFormControl('', [
      Validators.minLength(10),
      Validators.maxLength(10),
      conditionalValidator(() => this.birthDate.value?.length > 0, personBirthDateValidator)
    ]);
    this.rg = this.form.get('rg');
    this.cpf = this.form.get('cpf');
    this.cnpj = this.form.get('cnpj');
    this.passport = this.form.get('passport');
    this.ra = this.form.get('ra');
    this.phone = new UntypedFormControl('', [Validators.compose([Validators.minLength(14), Validators.maxLength(15)])]);
    this.phone2 = new UntypedFormControl('', [Validators.compose([Validators.minLength(14), Validators.maxLength(15)])]);
    this.phone3 = new UntypedFormControl('', [Validators.compose([Validators.minLength(14), Validators.maxLength(15)])]);
    this.occupation = this.form.get('occupation');
    this.maritalStatus = this.form.get('maritalStatus');
    this.specialNeeds = this.form.get('specialNeeds');
    this.specialNeedsDetails = this.form.get('specialNeedsDetails');

    this.subscriptions.add(this.specialNeeds.valueChanges.pipe(filter(v => !v)).subscribe(() => this.specialNeedsDetails.setValue('')));

    this.subscriptions.add(
      this.cpf.valueChanges.subscribe(v => {
        if (v.length) {
          this.cpf.setValidators([cpfValidator]);
        } else {
          this.cpf.clearValidators();
        }
        const formatted = formatCpf(v);
        this.cpf.setValue(formatted, { emitEvent: false });
      })
    );

    this.subscriptions.add(
      this.cnpj.valueChanges.subscribe(v => {
        if (v.length) {
          this.cnpj.setValidators([emptyOrValidCnpjValidator]);
        } else {
          this.cnpj.clearValidators();
        }
        const formatted = formatCnpj(v);
        this.cnpj.setValue(formatted, { emitEvent: false });
      })
    );

    const emailChangeSubscription = this.email.valueChanges
      .pipe(
        filter(v => v),
        debounceTime(250),
        map(value => value.trim()),
        mergeMap(value => this.condoService.emailAlreadyTaken(this.condo._id, value))
      )
      .subscribe({
        next: ({ emailAlreadyTaken, requiredFields }) => {
          const formFields: Record<ContactID['type'], AbstractControl> = {
            RG: this.rg,
            CPF: this.cpf,
            PASSPORT: this.passport,
            CNPJ: this.cnpj,
            RA: this.ra
          };
          const fields = emailAlreadyTaken ? requiredFields : Object.keys(formFields);
          const asyncValidatorBuilder = (field: string) => {
            return AsyncValidators.validateDocumentWithEmail(this.condoService, this.condo._id, field, `required${field}Document`);
          };

          for (const field of fields) {
            const control = formFields[field];
            if (control) {
              if (emailAlreadyTaken) {
                control.addAsyncValidators(asyncValidatorBuilder(field));
                control.markAsTouched();
              } else {
                control.clearValidators();
                control.clearAsyncValidators();
              }
              control.updateValueAndValidity();
            }
          }

          this.email.setValue(this.email.value.trim(), { emitEvent: false });
        },
        error: error => console.log(error)
      });
    this.subscriptions.add(emailChangeSubscription);

    this.subscriptions.add(
      this.phone.valueChanges.pipe(filter(v => v)).subscribe(v => {
        const formatted = formatPhone(v);
        this.phone.setValue(formatted, { emitEvent: false });
      })
    );

    this.subscriptions.add(
      this.phone2.valueChanges.pipe(filter(v => v)).subscribe(v => {
        const formatted = formatPhone(v);
        this.phone2.setValue(formatted, { emitEvent: false });
      })
    );

    this.subscriptions.add(
      this.phone3.valueChanges.pipe(filter(v => v)).subscribe(v => {
        const formatted = formatPhone(v);
        this.phone3.setValue(formatted, { emitEvent: false });
      })
    );

    this.subscriptions.add(
      this.form.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
        const formInvalid = Object.keys(this.form.controls).some(
          key => this.form.get(key).invalid && !this.form.get(key).value.includes('*')
        );
        if (!formInvalid && this.form.dirty) {
          this.globals.userIsValid = false;
          this.saveUserButtonAndLabelDisabled = false;
        } else {
          this.saveUserButtonAndLabelDisabled = true;
        }
      })
    );

    if (this.globals.user) {
      this.setEntireForm();
    }
  }

  setEntireForm() {
    this.initializeSensitiveFields();

    this.name.setValue(this.globals.user?.fullName || '', { emitEvent: false });
    this.email.setValue(this.globals.user?.email || '', { emitEvent: false });
    this.picture.setValue(this.globals.user?.picture || null, { emitEvent: false });
    if (this.globals.user?.birthDate) {
      this.birthDate.setValue(moment(this.globals.user.birthDate).format('YYYY-MM-DD'), { emitEvent: false });
    } else {
      this.birthDate.setValue(null, { emitEvent: false });
    }
    this.cnpj.setValue(this.globals.user?.cnpj?.number || '', { emitEvent: false });
    this.rg.setValue(this.globals.user?.rg?.number || '', { emitEvent: false });
    this.cpf.setValue(this.globals.user?.cpf?.number || '', { emitEvent: false });
    this.passport.setValue(this.globals.user?.passport?.number || '', { emitEvent: false });
    this.ra.setValue(this.globals.user?.ra?.number || '', { emitEvent: false });
    if (this.globals.user?.phones.length) {
      let phone = this.globals.user.phones[0];
      let phone2 = this.globals.user.phones[1] || '';
      let phone3 = this.globals.user.phones[2] || '';
      phone = formatPhone(phone);
      phone2 = formatPhone(phone2);
      phone3 = formatPhone(phone3);
      this.phone.setValue(phone, { emitEvent: false });
      this.phone2.setValue(phone2, { emitEvent: false });
      this.phone3.setValue(phone3, { emitEvent: false });
    } else {
      this.phone.setValue('', { emitEvent: false });
      this.phone2.setValue('', { emitEvent: false });
      this.phone3.setValue('', { emitEvent: false });
    }
    this.occupation.setValue(this.globals.user?.occupation || '', { emitEvent: false });
    this.maritalStatus.setValue(this.globals.user?.maritalStatus || '', { emitEvent: false });
    this.specialNeeds.setValue(this.globals.user?.specialNeeds || false, { emitEvent: false });
    this.specialNeedsDetails.setValue(this.globals.user?.specialNeedsDetails || '', { emitEvent: false });
    this.globals.userIsValid = this.form.valid;
  }

  initializeSensitiveFields() {
    if (!this.globals.user?.cpf) {
      this.form.addControl('cpf', this.cpf);
    }
    if (!this.globals.user?.cnpj) {
      this.form.addControl('cnpj', this.cnpj);
    }
    if (!this.globals.user?.rg) {
      this.form.addControl('rg', this.rg);
    }
    if (!this.globals.user?.passport) {
      this.form.addControl('passport', this.passport);
    }
    if (!this.globals.user?.ra) {
      this.form.addControl('ra', this.ra);
    }
    if (!this.globals.user?.email) {
      this.form.addControl('email', this.email);
    }
    if (!this.globals.user?.phones[0]) {
      this.form.addControl('phone', this.phone);
    }
    if (!this.globals.user?.phones[1]) {
      this.form.addControl('phone2', this.phone2);
    }
    if (!this.globals.user?.phones[2]) {
      this.form.addControl('phone3', this.phone3);
    }
    if (!this.globals.user?.birthDate) {
      this.form.addControl('birthDate', this.birthDate);
    }
  }

  cleanForm() {
    this.saveUserButtonAndLabelDisabled = true;
    this.setEntireForm();
  }

  submit(values) {
    const data = this.buildUser(values);
    this.status = this.STATUS.LOADING;
    if (!this.globals.user) {
      this.subscriptions.add(
        this.condoService
          .addUserToCondo(this.globals.condo?._id, data)
          .pipe(timeout(10000))
          .subscribe(
            (res: any) => {
              this.status = this.STATUS.SUCCESS;
              const user = new User({ ...data, _id: res._id });
              this.saveUser.emit(user);
            },
            (err: Error) => {
              this.status = this.STATUS.ERROR;
              let errorMessage;
              const userAlreadyIsCondoUser = err.originalErrorMessage === 'User already is condo user';
              if (userAlreadyIsCondoUser) {
                errorMessage = 'Usuário já cadastrado no ' + this.condo?.customLabels?.condo?.singular;
              } else {
                errorMessage = 'Não foi possível registrar o usuário, verifique sua conexão e tente novamente';
              }
              swal({
                type: 'error',
                title: 'Ops...',
                text: errorMessage
              });
            }
          )
      );
    } else {
      this.subscriptions.add(
        this.condoService
          .updateUser(this.condo._id, this.globals.user?._id, data)
          .pipe(timeout(10000))
          .subscribe(
            (res: any) => {
              this.status = this.STATUS.SUCCESS;
              const user = new User({
                ...this.globals.user,
                ...data,
                residencesVoter: this.globals.user?.residencesVoter,
                residencesUser: this.globals.user?.residencesUser
              });
              this.saveUser.emit(user);
            },
            err => {
              console.log({ err });
              this.status = this.STATUS.ERROR;
              if (err.originalErrorMessage && err.originalErrorMessage.includes('E11000 duplicate')) {
                swal({
                  type: 'error',
                  title: 'E-mail já cadastrado',
                  text: 'Este e-mail já está cadastrado em outro usuário do sistema.'
                });
              } else if (err.originalErrorMessage && err.originalErrorMessage.includes('USER_OF_MULTIPLE_CONDO')) {
                swal({
                  type: 'error',
                  title: 'Ops...',
                  text: `A conta do usuário está em mais de um(a) ${this.condo.customLabels.condo.singular} e apenas o usuário pode executar essa ação`
                });
              } else {
                swal({
                  type: 'error',
                  title: 'Ops...',
                  text:
                    'Não foi possível atualizar o ' +
                    (this.condo?.customLabels?.resident?.singular || 'condômino') +
                    ', verifique sua conexão e tente novamente'
                });
              }
            }
          )
      );
    }
  }

  sendConfirmationEmail() {
    if (this.email?.value && this.email?.valid) {
      this.condoService.sendConfirmationMail(this.globals.condo._id, this.globals.user._id).subscribe(
        () => {
          this.toastrService.success('E-mail de confirmação enviado.');
        },
        err => {
          this.toastrService.error('Erro ao enviar o e-mail. Tente novamente.');
        }
      );
    } else {
      this.toastrService.warning('E-mail inválido ou não preenchido.');
    }
  }

  buildUser(values) {
    const names = values.name.split(' ').map(name => name.trim());
    const firstName = names.shift();
    const lastName = names.join(' ');
    const picture = values.picture;
    const maritalStatus = values.maritalStatus;
    const occupation = values.occupation;

    const data: any = {
      firstName,
      lastName,
      picture: picture || null,
      maritalStatus: maritalStatus || '',
      occupation: occupation || '',
      specialNeeds: values.specialNeeds || false,
      specialNeedsDetails: values.specialNeedsDetails || ''
    };

    if (values.email && values.email.includes('*')) {
      delete values.email;
    }

    if (values.email) {
      data.email = values.email;
    }

    const phones: string[] = [];
    if (this.globals.user) {
      phones.push(values.phone, values.phone2, values.phone3);
    } else {
      // Se ele não existir é outra
      if (values.phone) {
        phones.push(values.phone.replace(/\D/g, ''));
      } // Remove tudo o que não é dígito
      if (values.phone2) {
        phones.push(values.phone2.replace(/\D/g, ''));
      } // Remove tudo o que não é dígito
      if (values.phone3) {
        phones.push(values.phone3.replace(/\D/g, ''));
      } // Remove tudo o que não é dígito
    }

    if (phones.length) {
      data.phones = phones;
    }

    const ids = [];
    if (values.cnpj) {
      ids.push({ type: ContactID.TYPES.CNPJ, number: values.cnpj });
    }

    if (values.cpf) {
      values.cpf = values.cpf.replace(/[^*\d]/g, ''); // Remove tudo o que não é dígito
      ids.push({ type: ContactID.TYPES.CPF, number: values.cpf });
    }

    if (values.rg) {
      values.rg = values.rg.replace(/[^*\d]/g, ''); // Remove tudo o que não é dígito
      ids.push({ type: ContactID.TYPES.RG, number: values.rg });
    }

    if (values.passport) {
      values.passport = values.passport.replace(/[^*A-z0-9]/g, ''); // Remove tudo o que não é número ou letra
      ids.push({ type: ContactID.TYPES.PASSPORT, number: values.passport });
    }

    if (values.ra) {
      values.ra = values.ra.replace(/[^*A-z0-9]/g, ''); // Remove tudo o que não é número ou letra
      ids.push({ type: ContactID.TYPES.RA, number: values.ra });
    }

    if (ids.length) {
      data.ids = ids;
    }

    if (values.birthDate && new Date(values.birthDate).getFullYear() === 3000) {
      delete values.birthDate;
    }

    if (values.birthDate && values.birthDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
      data.birthDate = values.birthDate;
    }

    return data;
  }

  submitForm() {
    if (this.form.valid) {
      this.form.value.phone = this.phone.value || '';
      this.form.value.phone2 = this.phone2.value || '';
      this.form.value.phone3 = this.phone3.value || '';

      this.submit(this.form.value);
      this.saveUserButtonAndLabelDisabled = true;
      this.globals.userIsValid = true;
    } else {
      for (const key of Object.keys(this.form.value)) {
        this.form.get(key).markAsTouched();
      }
    }
  }

  enableFieldToEdit(field: string) {
    this.editField[field] = !this.editField[field];

    if (field === 'phones' && this.editField[field]) {
      this.unmaskField(field);
      this.form.addControl('phone', this.phone);
      this.form.addControl('phone2', this.phone2);
      this.form.addControl('phone3', this.phone3);
    } else if (this.editField[field]) {
      this.unmaskField(field);
      this.form.addControl(field, this[field]);
    } else {
      this.returnMaskedValue(field);

      if (field === 'phones') {
        this.form.removeControl('phone');
        this.form.removeControl('phone2');
        this.form.removeControl('phone3');
      } else {
        this.form.removeControl(field);
      }
    }
    this.form.updateValueAndValidity();
  }

  unmaskField(field: string) {
    let fieldToSearch: string;
    const idTypes = ['cpf', 'rg', 'cnpj', 'passport', 'ra'];

    if (idTypes.includes(field)) {
      fieldToSearch = 'ids';
    } else {
      fieldToSearch = field;
    }

    const query: EcondosQuery = {
      $select: fieldToSearch,
      $and: []
    };

    let callback;

    switch (fieldToSearch) {
      case 'email':
        callback = ({ data }) => this[field].setValue(data);
        break;

      case 'phones':
        callback = ({ data }) => {
          if (data[0]) {
            this.phone.setValue(data[0]);
          }
          if (data[1]) {
            this.phone2.setValue(data[1]);
          }
          if (data[2]) {
            this.phone3.setValue(data[2]);
          }
        };
        break;

      case 'ids':
        callback = ({ data }) => {
          if (data.find(id => id.type === ContactID.TYPES.CPF) && field === 'cpf') {
            this[field].setValue(data.find(id => id.type === ContactID.TYPES.CPF).number);
          }

          if (data.find(id => id.type === ContactID.TYPES.CNPJ) && field === 'cnpj') {
            this[field].setValue(data.find(id => id.type === ContactID.TYPES.CNPJ).number);
          }

          if (data.find(id => id.type === ContactID.TYPES.RG) && field === 'rg') {
            this[field].setValue(data.find(id => id.type === ContactID.TYPES.RG).number);
          }

          if (data.find(id => id.type === ContactID.TYPES.PASSPORT) && field === 'passport') {
            this[field].setValue(data.find(id => id.type === ContactID.TYPES.PASSPORT).number);
          }
          if (data.find(id => id.type === ContactID.TYPES.RA) && field === 'ra') {
            this[field].setValue(data.find(id => id.type === ContactID.TYPES.RA).number);
          }
        };
        break;

      case 'birthDate':
        callback = ({ data }) => {
          if (data) {
            this[field].setValue(moment(data).format('YYYY-MM-DD'), { emitEvent: false });
          }
        };
        break;
    }

    this.condoService
      .getCondoResidentUnmaskedField(this.globals.condo?._id, query, this.globals.user._id, fieldToSearch)
      .pipe(timeout(10000))
      .subscribe(callback);
  }

  returnMaskedValue(field: string) {
    switch (field) {
      case 'email':
        if (this.globals.user.email) {
          this[field].setValue(this.globals.user.email);
        }
        break;

      case 'cpf':
        if (this.globals.user.cpf) {
          this[field].setValue(this.globals.user.ids.find(id => id.type === ContactID.TYPES.CPF).number, { emitEvent: false });
        }
        break;

      case 'cnpj':
        if (this.globals.user.cnpj) {
          this[field].setValue(this.globals.user.ids.find(id => id.type === ContactID.TYPES.CNPJ).number, { emitEvent: false });
        }
        break;

      case 'rg':
        if (this.globals.user.rg) {
          this[field].setValue(this.globals.user.ids.find(id => id.type === ContactID.TYPES.RG).number, { emitEvent: false });
        }
        break;

      case 'passport':
        if (this.globals.user.passport) {
          this[field].setValue(this.globals.user.ids.find(id => id.type === ContactID.TYPES.PASSPORT).number, { emitEvent: false });
        }
        break;

      case 'ra':
        if (this.globals.user.ra) {
          this[field].setValue(this.globals.user.ids.find(id => id.type === ContactID.TYPES.RA).number, { emitEvent: false });
        }
        break;

      case 'phones':
        if (this.globals.user.phones[0]) {
          this.phone.setValue(this.globals.user.phones[0], { emitEvent: false });
        }
        if (this.globals.user.phones[1]) {
          this.phone2.setValue(this.globals.user.phones[1], { emitEvent: false });
        }
        if (this.globals.user.phones[2]) {
          this.phone3.setValue(this.globals.user.phones[2], { emitEvent: false });
        }
        break;

      case 'birthDate':
        if (this.globals.user.birthDate) {
          this[field].setValue(moment(this.globals.user.birthDate).format('YYYY-MM-DD'), { emitEvent: false });
        }
        break;
    }
  }

  resetUserPassword(user) {
    const userCondos = [...user.condos, ...user.condosAdmin, ...user.condosOwner, ...user.condosJanitor];
    const isAdminInAllCondos = user.condos.every(
      condo =>
        this.user.condosAdmin.some(condoadmin => condoadmin._id === condo._id) ||
        this.user.condosOwner.some(condoowner => condoowner._id === condo._id)
    );
    if (!isAdminInAllCondos && (user.condosOwner.length || userCondos.length > 1)) {
      swal({
        type: 'warning',
        text:
          'Este usuário está presente em mais de um(a) ' +
          this.condo?.customLabels?.condo?.singular +
          ' e usa o aplicativo. Entre em contato diretamente com o usuário ou com o suporte eCondos para alterar a senha deste usuário'
      });
    } else {
      swal({
        type: 'question',
        title: 'Resetar senha',
        text: `Gostaria realmente de resetar a senha do usuário ${user.firstName} ${user.lastName}?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return lastValueFrom(this.condoService.resetPassword(this.condo._id, user._id).pipe(timeout(10000))).catch(err =>
            Promise.reject('Não foi possível resetar a senha deste usuário.')
          );
        }
      }).then(
        res => {
          swal({
            type: 'success',
            title: 'Senha resetada',
            html: `A nova senha do usuário é <b>${res.password}</b>`
          });
        },
        err => {
          // Clicked cancel
        }
      );
    }
  }
}
