import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User } from '../../api/model/user';
import { UtilService } from '../../services/util.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AccessControlComponent } from './access.control/access.control';
import { CondoVisitorsComponent } from './visitors/condo.visitors';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { GatePermissionsListPage } from './gate.permissions.list/gate.permissions.list';
import { Subject } from 'rxjs';
import { GateResidenceDetailsModalComponent } from '../gate-residence-details.modal/gate-residence-details.modal.component';
import { ParamsService } from '@api/service/params.service';
import { HardwareSocketService } from '../../services/hardware-socket.service';
import { Condo } from '@api/model/condo';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GateResidentDetailsModalComponent } from './gate-resident-details.modal/gate-resident-details.modal.component';
import { capitalize } from '@api/util/util';
import { PERMISSIONS } from '@api/model/custom-role/custom-role-permissions';

@Component({
  templateUrl: 'gate.html',
  styleUrls: ['gate.scss']
})
export class GatePage implements OnInit, OnDestroy {
  @ViewChild(AccessControlComponent) accessControlComponent: AccessControlComponent;
  @ViewChild(CondoVisitorsComponent) condoVisitorsComponent: CondoVisitorsComponent;
  @ViewChild(GatePermissionsListPage) gatePermissionsListPage: GatePermissionsListPage;

  public condo;
  public user: User;

  // Tabs vars
  selectedTabIndex;

  eventsIndex = 0;
  notebookIndex = 1;
  deliveryIndex = 2;
  keyIndex = 3;
  permissionIndex = 4;
  accessIndex = 5;
  activeVisitorIndex = 6;
  visitorIndex = 7;
  userEventIndex = 8;
  private unsubscribe: Subject<void> = new Subject();

  tabs: GateTab[] = [];

  constructor(
    private utilService: UtilService,
    private modalService: BsModalService,
    private paramsService: ParamsService,
    private hardwareSocketService: HardwareSocketService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.user = this.utilService.getLocalUser();
    this.condo = this.utilService.getLocalCondo();
    this.tabs = this.loadTabs(this.user, this.condo);
    this.subscribeRouteChanges();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  subscribeRouteChanges() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe)
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url === '/gate') {
          const route = (this.tabs[0] && this.tabs[0].route) || 'notFound';
          this.selectedTabIndex = this.tabs[0] && this.tabs[0].index;
          this.navigate(route);
        } else {
          const selectedTab = this.tabs.find(tab => event.url.includes(tab.route));
          this.selectedTabIndex = (selectedTab && selectedTab.index) || 0;
        }
      });
  }

  loadTabs(user: User, condo: Condo) {
    const hasAccessLiberationPermission = this.user.getPermissionValue({
      condoId: this.condo._id,
      permission: PERMISSIONS.accessLiberation.view
    });

    const tabs = [];
    if (this.user.isOnlyJanitor()) {
      tabs.push({
        title: 'Encomendas',
        icon: 'fa-archive',
        index: this.deliveryIndex,
        testId: 'deliveries',
        route: 'deliveries',
        onSelect: route => this.navigate(route)
      });
    } else {
      // Events tab
      tabs.push({
        title: 'Eventos',
        icon: 'fa-id-badge',
        index: this.eventsIndex,
        testId: 'events',
        route: 'events',
        onSelect: route => this.navigate(route)
      });
      // Notebook tab
      if (!condo.isGateNotebookDisabled()) {
        tabs.push({
          title: 'Livro',
          icon: 'fa-book',
          index: this.notebookIndex,
          testId: 'notebook',
          route: 'notebook',
          onSelect: route => this.navigate(route)
        });
      }
      // Deliveries
      if (!condo.isDeliveriesDisabled()) {
        tabs.push({
          title: 'Encomendas',
          icon: 'fa-archive',
          index: this.deliveryIndex,
          testId: 'deliveries-2',
          route: 'deliveries',
          onSelect: route => this.navigate(route)
        });
      }
      // Keys
      if (!condo.isKeysDisabled()) {
        tabs.push({
          title: 'Chaves',
          icon: 'fa-key',
          index: this.keyIndex,
          testId: 'keys',
          route: 'keys',
          onSelect: route => this.navigate(route)
        });
      }
      // Permissions
      if (!condo.isAccessLiberationDisabled() && hasAccessLiberationPermission) {
        tabs.push({
          title: `${capitalize(condo.customLabels?.visitor?.plural || 'visitantes')} autorizados`,
          icon: 'fa-vcard',
          index: this.permissionIndex,
          testId: 'permissions',
          route: 'permissions',
          onSelect: route => this.navigate(route)
        });
      }
      // Access control
      if (!condo.isAccessControlDisabled() && hasAccessLiberationPermission) {
        tabs.push({
          title: 'Controle de acesso',
          icon: 'fa-exchange',
          testId: 'access-control',
          index: this.accessIndex,
          route: 'access',
          onSelect: route => this.navigate(route)
        });

        tabs.push({
          title: `${capitalize(condo.customLabels?.visitor?.plural || 'visitantes')} ativos`,
          icon: 'fa-users',
          index: this.activeVisitorIndex,
          testId: 'active-visitors',
          route: 'active-visitors',
          onSelect: route => this.navigate(route)
        });

        tabs.push({
          title: `${capitalize(condo.customLabels?.visitor?.plural || 'visitantes')} cadastrados`,
          icon: 'fa-address-book-o',
          index: this.visitorIndex,
          testId: 'visitants',
          route: 'visitants',
          onSelect: route => this.navigate(route)
        });
      }
    }
    return tabs;
  }
  navigate(route: string) {
    if (!this.router.url.includes(route)) {
      this.router.navigate([`gate/${route}`]);
    }
  }

  changeTabByDropdown(tabIndex) {
    tabIndex = parseInt(tabIndex, 10);
    const tab = this.tabs.find(t => t.index === tabIndex);
    this.navigate(tab.route);
  }

  onResidenceSelect(residence) {
    if (residence?._id) {
      const initialState = { condo: this.condo, residenceId: residence._id };
      this.modalService.show(GateResidenceDetailsModalComponent, { initialState, class: 'modal-lg modal-xl', ignoreBackdropClick: true });
    }
  }

  onUserSelect(user) {
    if (user?._id) {
      const initialState = { condo: this.condo, userId: user._id };
      this.modalService.show(GateResidentDetailsModalComponent, { initialState, class: 'modal-lg modal-xl', ignoreBackdropClick: true });
    }
  }
}

interface GateTab {
  icon: string;
  title: string;
  testId?: string;
  onSelect: Function;
  index: number;
  route: string;
}
