import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EcondosFilter } from '@api/model/filter';

@Component({
  selector: 'active-filters',
  template: `
    <div class="d-flex flex-wrap gap-1">
      <ng-container *ngFor="let filter of filters; let i = index">
        <span
          class="bg-neutral-200 text-neutral-600 px-3 py-1 text-sm leading-none rounded-pill"
          style="vertical-align: middle"
          *ngIf="filter.value">
          {{ filter.label + ': ' + filter.valueLabel }}
          <button
            class="p-0 ms-2"
            style="background: transparent; border: none"
            (click)="onCloseClick(filter.value, i)"
            [attr.data-testid]="'active-filters-' + (i + 1) + '-close-button'">
            <i class="fa fa-close"></i>
          </button>
        </span>
      </ng-container>
    </div>
  `
})
export class ActiveFiltersComponent {
  @Input() filters: EcondosFilter[] = [];

  @Output() onRemoveClick = new EventEmitter();

  constructor() {}

  onCloseClick(value, index) {
    this.onRemoveClick.emit({
      value,
      index
    });
  }
}
