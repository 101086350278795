import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Condo } from '@api/model/condo';
import { Device } from '@api/model/hardware/device';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CreateDeviceStepComponent } from './create-device-step/create-device-step.component';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '../../services/util.service';
import swal from 'sweetalert2';
import { defaultIfEmpty, forkJoin, lastValueFrom } from 'rxjs';
import { User } from '@api/model/user';
import { Residence } from '@api/model/interface/residence';
import { UserLocalSettingsService } from '@api/serviceV2/user-local-settings.service';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { map } from 'rxjs/operators';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { HardwareDeviceService } from '@api/service/hardware/hardware-device.service';
import { UpsertDeviceResponse } from '@api/service/hardware/sdk.service';
import { buildHardwareSyncErrorFeedbackHtml } from 'utils/buildHardwareSyncErrorFeedbackHtml';

@Component({
  selector: 'app-modal-create-device',
  templateUrl: './modal-create-device.component.html',
  styleUrls: ['./modal-create-device.component.scss']
})
export class ModalCreateDeviceComponent implements OnInit, AfterViewInit {
  @ViewChild(CreateDeviceStepComponent) stepper: CreateDeviceStepComponent;

  currentStep = 0;

  condo: Condo;

  isOnlyHikvisionEnabled = false;
  onUseDeprecatedHikvisionButtonClick: () => void;

  device: Device;
  callback: (arg) => void;

  status: 'LOADING' | 'SUCCESS' | 'ERROR';
  statusMessage = '';
  storageUser: { user: User; residence: Residence };

  constructor(
    public bsModalRef: BsModalRef,
    private deviceService: HardwareDeviceService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private actuatorService: ActuatorService,
    private userLocalSettingsService: UserLocalSettingsService
  ) {}

  ngOnInit(): void {
    this.condo = this.utilService.getLocalCondo();

    const isHikvisionEnabled = this.condo.isHikvisionEnabled();
    const isBravasEnabled = this.condo.isBravasEnabled();
    const isXPEEnabled = this.condo.isXPEEnabled();
    const isMip1000IpEnabled = this.condo.isMIP1000IPEnabled();

    this.isOnlyHikvisionEnabled = isHikvisionEnabled && !isBravasEnabled && !isXPEEnabled && !isMip1000IpEnabled;
  }

  ngAfterViewInit(): void {
    if (this.device) {
      this.stepper.setValueFromDevice(this.device);
      this.stepper.setCurrentStep(3);
    }

    if (this.storageUser) {
      this.stepper.setValueFromStorageUser(this.storageUser);
    }
  }

  dryDevice(device) {
    const dryedDevice = {
      ...device,
      owner: {
        ...device.owner,
        residence: device.owner.residence?._id || device.owner.residence
      }
    };

    if (device.owner.user) {
      dryedDevice.owner.user = device.owner.user?._id || device.owner.user;
    } else if (device.owner.condoContact) {
      dryedDevice.owner.condoContact = device.owner.condoContact?._id || device.owner.condoContact;
    } else if (device.owner.dependent) {
      dryedDevice.owner.dependent = device.owner.dependent?._id || device.owner.dependent;
    }

    if (device.owner.condoVehicle) {
      dryedDevice.owner.condoVehicle = device.owner.condoVehicle?._id || device.owner.condoVehicle;
    }

    if (device.owner.picture) {
      dryedDevice.owner.picture = device.owner.picture?._id || device.owner.picture;
    }

    return dryedDevice;
  }

  async submit() {
    if (this.stepper.form.valid) {
      try {
        let device = this.stepper.getValueAsDevice();
        this.status = 'LOADING';
        let response;
        const dryedDevice = this.dryDevice(device);
        const isEdit = !!this.device;
        if (isEdit) {
          device = { ...device, internalIds: this.device.internalIds };
          this.statusMessage = 'Atualizando dados...';
          response = await lastValueFrom(
            this.deviceService.update(this.condo._id, this.device._id, { ...dryedDevice, status: 'UNSYNCED' })
          );
        } else {
          this.statusMessage = 'Registrando dados...';
          response = await lastValueFrom(this.deviceService.create(this.condo._id, dryedDevice));
        }
        device._id = this.device?._id || response._id;
        if (response.sequenceId) {
          device.sequenceId = response.sequenceId;
        }

        if (isEdit) {
          this.statusMessage = 'Excluindo dos equipamentos necessários...';
          const shouldContinue = await this.removeFromOthersActuators(device);
          if (!shouldContinue) {
            this.statusMessage = '';
            this.status = 'ERROR';
            return;
          }
        }

        this.statusMessage = 'Enviando dados para os equipamentos...';

        const hardwareResponse: UpsertDeviceResponse[] =
          (await lastValueFrom(forkJoin(...this.deviceService.saveOnHardware(device)).pipe(defaultIfEmpty([])))) || [];

        const errorsResponses = hardwareResponse?.filter(r => !r.success);

        if (errorsResponses.length) {
          await lastValueFrom(this.deviceService.update(this.condo._id, device._id, { status: 'UNSYNCED' }));

          const html = buildHardwareSyncErrorFeedbackHtml(errorsResponses);

          swal({
            type: 'error',
            title: 'Falha ao cadastrar dispositivo',
            html
          });
        } else {
          const internalIdsFromRequest = hardwareResponse.map(r => r?.data?.internalIds || []).flat();
          const internalIds = internalIdsFromRequest || device.internalIds || [];
          this.toastr.success('Dispositivo registrado com sucesso');
          await lastValueFrom(this.deviceService.update(this.condo._id, device._id, { status: 'SYNCED', internalIds }));
        }
        this.statusMessage = '';
        this.status = 'SUCCESS';
        if (this.callback) {
          this.callback(new Device(response));
        }
        this.bsModalRef.hide();
      } catch (e) {
        console.log(e);
        this.statusMessage = '';
        this.status = 'ERROR';
        swal({
          type: 'error',
          title: 'Falha ao cadastrar dispositivo',
          text: `Não foi possível fazer o cadastro corretamente, verifique os dados do dispositivo e os equipamentos e tente novamente`
        });
      }
    } else {
      this.toastr.warning('Preencha todos os campos');
      this.stepper.form.markAllAsTouched();
    }
  }

  async removeFromOthersActuators(device: Device): Promise<boolean> {
    const actuatorQuery = {
      hardware: { $in: [HARDWARES.BRAVAS, HARDWARES.HIKVISION, HARDWARES.XPE, HARDWARES.MIP1000IP] }
    };
    const condoActuators = await lastValueFrom(
      this.actuatorService.getActuators(this.condo._id, actuatorQuery).pipe(map(({ actuators }) => actuators))
    );
    const removeReponse: any[] = await lastValueFrom(this.deviceService.removeFromHardware(this.condo._id, device, condoActuators));
    const failedToRemove = removeReponse.filter(r => !r.success);
    // Se falhar a remoção nós avisamos o usuário para ele decidir se deseja prosseguir ou não com o processo de cadastro
    if (failedToRemove.length) {
      const actuatorsThatFailed = failedToRemove.map(r => r.actuator.name).join(', ');
      const html = `
      <div>O sistema não conseguiu remover o cadastro dos equipamentos listados abaixo:</div>
      <div>${actuatorsThatFailed}</div>
      <div>Deseja prosseguir mesmo assim?</div>
      `;
      const result = await swal({
        type: 'question',
        html,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true
      })
        .then(() => true)
        .catch(() => false);
      return result;
    } else {
      return true;
    }
  }

  handleStepChange(step: number) {
    this.currentStep = step;
  }

  handleUseDeprecatedHikvision() {
    this.bsModalRef.hide();

    this.userLocalSettingsService.saveSetting({ key: 'useDeprecatedHikvision', value: true });

    if (this.onUseDeprecatedHikvisionButtonClick) {
      this.onUseDeprecatedHikvisionButtonClick();
    }
  }
}
