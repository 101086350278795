import { Component, OnDestroy, OnInit } from '@angular/core';
import { CondoService } from '@api/service/condo.service';
import { Condo } from '@api/model/condo';
import { Status } from '@api/model/status';
import { ToastrService } from 'ngx-toastr';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil, timeout, timeoutWith } from 'rxjs/operators';
import { UtilService } from '../../../services/util.service';
import swal from 'sweetalert2';
import { ModalGenerateQrcodeComponent } from '../../../components/modal-generate-qrcode/modal-generate-qrcode.component';
import { Subject } from 'rxjs';
import { CondoContact } from '@api/model/contact/condo.contact';
import * as moment from 'moment';
import { SessionService } from '@api/service/session.service';
import { defaultSortFunction } from '@api/util/util';
import { ThemeService } from '../../../theme';
import { ErrorBuilder } from '@api/model/error/error.builder';
import { VirtualKeyService } from '@api/service/virtual-key.service';
import { VirtualKey } from '@api/model/virtual-key';
import { EcondosQuery } from '@api/model/query';

type ParamConfig = {
  icon?: string;
  label: string;
  description?: string;
  true?: any;
  false?: any;
  value?: any;
  toggleValue?: any;
  originalValue?: any;
  paramName?: string;
  isSubSectionTitle?: boolean;
  available?: boolean;
  required?: boolean;
  show?: boolean;
};

type ParamsSection = {
  title: string;
  icon: string;
  tag: string;
  params: Record<string, ParamConfig>;
};

@Component({
  selector: 'app-modal-gate-permissions-config',
  templateUrl: 'modal-gate-permissions-config.component.html',
  styleUrls: ['modal-gate-permissions-config.component.scss']
})
export class ModalGatePermissionsConfigComponent implements OnInit, OnDestroy {
  form = this.formBuilder.group({
    geral: this.formBuilder.group({
      maxDays: new FormControl(0),
      useShouldCall: new FormControl(false),
      visitorSelfRegistration: new FormControl(false),
      visitorSelfRegistrationFromQrCode: new FormControl(false),
      selfAttendanceForAutonomousConcierges: new FormControl(false),
      virtualKeyOfSelfAttendanceForAutonomousConcierges: new FormControl<string | null>(null),
      residentCanSearchAllVisitors: new FormControl(false),
      residentCanRegisterAccessLiberation: new FormControl(false),
      gateKeeperCanRegisterVisitorPermission: new FormControl(false),
      gateKeeperCanShareVisitorPermissionLink: new FormControl(false),
      visitorAccessLiberationDefaultTime: this.formBuilder.group({
        startTime: moment().hour(8).minute(0).toDate(),
        endTime: moment().hour(17).minute(0).toDate()
      }),
      isAbleToEdit: new FormControl(false),
      allowRegisteringGenericDocument: new FormControl(false),
      visitorCanCreateMultipleVisitors: new FormControl(false),
      residentCanCreateVisitorIdentificationLiberation: new FormControl(false)
    }),
    morador: this.formBuilder.group({
      email: this.formBuilder.group({ available: true, required: { disabled: false, value: false } }),
      birthDate: this.formBuilder.group({ available: true, required: false }),
      phone: this.formBuilder.group({ available: true, required: false }),
      rg: this.formBuilder.group({ available: true, required: false }),
      cpf: this.formBuilder.group({ available: true, required: false }),
      picture: this.formBuilder.group({ available: true, required: false }),
      accessGroups: this.formBuilder.group({ available: true, required: false }),
      documentImage: this.formBuilder.group({ available: true, required: false })
    }),
    visitante: this.formBuilder.group({
      RESIDENT: new FormControl(true),
      PROVIDER: new FormControl(true),
      SUPPLIER: new FormControl(true),
      DELIVERYMAN: new FormControl(true),
      PUBLIC_AGENT: new FormControl(true),
      DRIVER: new FormControl(true),
      EMPLOYEE: new FormControl(true),
      RELATIVE: new FormControl(true),
      AIRBNB: new FormControl(true),
      TENANT: new FormControl(true),
      OTHER: new FormControl(true)
    })
  });

  defaultSortFunction = defaultSortFunction;

  CONDO_CONTACTS_LABELS = CondoContact.TYPES_LABEL;

  public condo: Condo = this.utilService.getLocalCondo();

  public CONDO_SECTION_PARAMS: Record<string, ParamsSection>;
  public selectedParamsSection: keyof typeof this.CONDO_SECTION_PARAMS = 'geral';

  status: Status = new Status();
  private unsubscribe: Subject<void> = new Subject();

  virtualKeys: VirtualKey[] = [];

  constructor(
    private condoService: CondoService,
    private sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    private utilService: UtilService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private themeService: ThemeService,
    private virtualKeyService: VirtualKeyService
  ) {
    this.setParamsSection();
  }

  setParamsSection() {
    this.CONDO_SECTION_PARAMS = {
      geral: {
        title: 'Geral',
        icon: 'cogs',
        tag: 'geral',
        params: {
          visitorSelfRegistration: {
            icon: 'address-card-o',
            label: `Auto cadastro de ${this.condo.customLabels?.visitor?.singular || 'visitante'}`,
            description: ` O ${this.condo.customLabels?.visitor?.singular || 'visitante'} pode fazer o auto cadastro no(a) ${
              this.condo?.customLabels?.condo.singular
            } a partir de link enviado por
            ${this.condo?.customLabels?.resident.singular}`,
            paramName: 'visitorSelfRegistration',
            show: true
          },
          residentCanRegisterAccessLiberation: {
            icon: 'user-plus',
            label: `Criação de liberação de ${this.condo.customLabels?.visitor?.plural || 'visitantes'} por ${
              this.condo?.customLabels?.resident.singular
            }`,
            description: `Quando habilitado ${this.condo?.customLabels?.resident.singular} podem criar liberações para ${
              this.condo.customLabels?.visitor?.plural || 'visitantes'
            }`,
            paramName: 'residentCanRegisterAccessLiberation',
            show: true
          },
          visitorAccessLiberationDefaultTime: {
            icon: 'clock-o',
            label: 'Horário',
            description: `Horário inicial para liberações`,
            paramName: 'visitorAccessLiberationDefaultTime',
            show: true
          },
          useShouldCall: {
            icon: 'phone',
            label: `Interfonar na liberação de ${this.condo.customLabels?.visitor?.singular || 'visitante'}`,
            description: `${this.condo?.customLabels?.resident.singular} pode definir se a liberação de ${
              this.condo.customLabels?.visitor?.singular || 'visitante'
            } deve ser interfonada`,
            paramName: 'useShouldCall',
            show: true
          },
          gateKeeperCanRegisterVisitorPermission: {
            icon: 'user',
            label: `Porteiros podem registrar liberação de ${this.condo.customLabels?.visitor?.plural || 'visitantes'}`,
            description: `Quando habilitado os porteiros conseguem registrar autorizações de ${
              this.condo.customLabels?.visitor?.plural || 'visitantes'
            } no sistema`,
            paramName: 'gateKeeperCanRegisterVisitorPermission',
            show: true
          },
          gateKeeperCanShareVisitorPermissionLink: {
            icon: 'link',
            label: `Porteiros podem compartilhar o link da liberação de ${this.condo.customLabels?.visitor?.plural || 'visitantes'}`,
            description: `Quando habilitado os porteiros conseguem compartilhar o link da liberação de ${
              this.condo.customLabels?.visitor?.plural || 'visitantes'
            } no sistema`,
            paramName: 'gateKeeperCanShareVisitorPermissionLink',
            show: false
          },
          maxDays: {
            icon: 'calendar-o',
            label: 'Prazo',
            description: `Prazo máximo para criar uma liberação`,
            paramName: 'maxDays',
            show: true
          },
          visitorSelfRegistrationFromQrCode: {
            icon: 'qrcode',
            label: `QR Code impresso para auto cadastro de ${this.condo.customLabels?.visitor?.singular || 'visitante'}`,
            description: `  O ${this.condo.customLabels?.visitor?.singular || 'visitante'} pode fazer o auto cadastro no(a) ${
              this.condo?.customLabels?.condo.singular
            } a partir da leitura de um QR Code impresso
            apresentado pela portaria`,
            paramName: 'visitorSelfRegistrationFromQrCode',
            show: true
          },
          selfAttendanceForAutonomousConcierges: {
            icon: 'qrcode',
            label: 'QR Code impresso para auto atendimento em portarias autônomas',
            description: `  O ${this.condo.customLabels?.visitor?.singular || 'visitante'} pode fazer o auto atendimento no(a) ${
              this.condo?.customLabels?.condo.singular
            } a partir da leitura de um QR Code impresso
            apresentado pela portaria. Funcionalidade exclusiva para o aplicativo eFone`,
            paramName: 'selfAttendanceForAutonomousConcierges',
            show: true
          },
          residentCanSearchAllVisitors: {
            icon: 'eye',
            label: `Visualização de todos ${this.condo.customLabels?.visitor?.plural || 'visitantes'} para ${
              this.condo?.customLabels?.resident.singular
            }`,
            description: `Quando habilitado ${this.condo?.customLabels?.resident.plural} podem pesquisar por todos ${
              this.condo.customLabels?.visitor?.plural || 'visitantes'
            } já cadastrados no(a)
            ${this.condo?.customLabels?.condo.singular}.`,
            paramName: 'residentCanSearchAllVisitors',
            show: true
          },
          isAbleToEdit: {
            icon: 'pencil',
            label: 'Editar liberação de acesso',
            description: `Quando habilitado permite realizar a edição de liberações de acesso pelo administrador ou por quem criou a liberação`,
            paramName: 'isAbleToEdit',
            show: true
          },
          allowRegisteringGenericDocument: {
            icon: 'id-card-o',
            label: 'Permitir cadastro de estrangeiros',
            description: `Quando habilitado o visitante que é estrangeiro consegue se cadastrar utilizando um documento diferente de RG e CPF`,
            paramName: 'allowRegisteringGenericDocument',
            show: true
          },
          visitorCanCreateMultipleVisitors: {
            icon: 'group',
            label: `Criação de múltiplas pessoas pelo ${this.condo?.customLabels?.visitor.singular}`,
            description: `Quando habilitado o ${this.condo?.customLabels?.visitor.singular} pode criar mais de uma pessoa durante o auto cadastro`,
            paramName: 'visitorCanCreateMultipleVisitors',
            show: true
          },
          residentCanCreateVisitorIdentificationLiberation: {
            icon: 'user-plus',
            label: 'Criação de liberação de acesso para entregadores ou motoristas',
            description: `Quando habilitado o ${this.condo?.customLabels?.resident?.singular} pode criar uma liberação de acesso para entregadores ou motoristas sem a necessidade de criar um visitante.`,
            paramName: 'residentCanCreateVisitorIdentificationLiberation',
            show: true
          }
        }
      },
      morador: {
        title: `Cadastro via ${this.condo.customLabels?.resident?.singular || 'morador'} `,
        icon: 'user',
        tag: 'morador',
        params: {
          cpf: {
            icon: 'id-card-o',
            label: 'CPF',
            description: `Parâmetros do CPF`,
            paramName: 'cpf',
            show: true
          },
          birthDate: {
            icon: 'calendar',
            label: 'Data de aniversário',
            description: `Parâmetros da data de aniversário`,
            paramName: 'birthDate',
            show: true
          },
          email: {
            icon: 'send',
            label: 'E-mail',
            description: `Parâmetros do e-mail`,
            paramName: 'email',
            show: true
          },
          picture: {
            icon: 'user-circle',
            label: 'Foto',
            description: `Parâmetros da foto`,
            paramName: 'picture',
            show: true
          },
          documentImage: {
            icon: 'address-card-o',
            label: 'Foto do documento',
            description: `Parâmetros da rota`,
            paramName: 'documentImage',
            show: true
          },
          rg: {
            icon: 'id-card-o',
            label: 'RG',
            description: `Parâmetros do RG`,
            paramName: 'rg',
            show: true
          },
          accessGroups: {
            icon: 'users',
            label: 'Rota',
            description: `Parâmetros da rota`,
            paramName: 'accessGroups',
            show: true
          },
          phone: {
            icon: 'phone',
            label: 'Telefone',
            description: `Parâmetros do telefone`,
            paramName: 'phone',
            show: true
          }
        }
      },
      visitante: {
        title: `Tipos de ${this.condo.customLabels?.visitor?.plural || 'visitantes'} disponiveis`,
        icon: 'list-ul',
        tag: 'visitante',
        params: {
          PUBLIC_AGENT: {
            icon: 'user-o',
            label: `${this.CONDO_CONTACTS_LABELS['PUBLIC_AGENT']}`,
            paramName: 'PUBLIC_AGENT',
            show: true
          },
          AIRBNB: {
            icon: 'home',
            label: `${this.CONDO_CONTACTS_LABELS['AIRBNB']}`,
            paramName: 'AIRBNB',
            show: true
          },
          RESIDENT: {
            icon: 'address-book-o',
            label: `${this.CONDO_CONTACTS_LABELS['RESIDENT']}`,
            paramName: 'RESIDENT',
            show: true
          },
          DELIVERYMAN: {
            icon: 'motorcycle',
            label: `${this.CONDO_CONTACTS_LABELS['DELIVERYMAN']}`,
            paramName: 'DELIVERYMAN',
            show: true
          },
          SUPPLIER: {
            icon: 'truck',
            label: `${this.CONDO_CONTACTS_LABELS['SUPPLIER']}`,
            paramName: 'SUPPLIER',
            show: true
          },
          EMPLOYEE: {
            icon: 'address-card-o',
            label: `${this.CONDO_CONTACTS_LABELS['EMPLOYEE']}`,
            paramName: 'EMPLOYEE',
            show: true
          },
          TENANT: {
            icon: 'id-badge',
            label: `${this.CONDO_CONTACTS_LABELS['TENANT']}`,
            paramName: 'TENANT',
            show: true
          },
          DRIVER: {
            icon: 'taxi',
            label: `${this.CONDO_CONTACTS_LABELS['DRIVER']}`,
            paramName: 'DRIVER',
            show: true
          },
          OTHER: {
            icon: 'user-plus',
            label: `${this.CONDO_CONTACTS_LABELS['OTHER']}`,
            paramName: 'OTHER',
            show: true
          },

          RELATIVE: {
            icon: 'child',
            label: `${this.CONDO_CONTACTS_LABELS['RELATIVE']}`,
            paramName: 'RELATIVE',
            show: true
          },
          PROVIDER: {
            icon: 'briefcase',
            label: `${this.CONDO_CONTACTS_LABELS['PROVIDER']}`,
            paramName: 'PROVIDER',
            show: true
          }
        }
      }
    };
  }

  ngOnInit(): void {
    this.setParams();

    const {
      maxDays,
      useShouldCall,
      visitorSelfRegistration,
      visitorSelfRegistrationFromQrCode,
      selfAttendanceForAutonomousConcierges,
      virtualKeyOfSelfAttendanceForAutonomousConcierges,
      residentCanSearchAllVisitors,
      residentCanRegisterAccessLiberation,
      gateKeeperCanRegisterVisitorPermission,
      gateKeeperCanShareVisitorPermissionLink,
      visitorAccessLiberationDefaultTime,
      isAbleToEdit,
      allowRegisteringGenericDocument,
      visitorCanCreateMultipleVisitors,
      residentCanCreateVisitorIdentificationLiberation
    } = this.condo.generalParams?.accessLiberation;

    const [hours, minutes] = visitorAccessLiberationDefaultTime?.startTime
      ? visitorAccessLiberationDefaultTime.startTime.split(':')
      : ['08', '00'];
    const startTime = moment().hour(parseInt(hours)).minute(parseInt(minutes));
    const [hoursEnd, minutesEnd] = visitorAccessLiberationDefaultTime?.endTime
      ? visitorAccessLiberationDefaultTime.endTime.split(':')
      : ['17', '00'];
    const endTime = moment().hour(parseInt(hoursEnd)).minute(parseInt(minutesEnd));

    const { email, birthDate, phone, rg, cpf, picture, documentImage, accessGroups } =
      this.condo.generalParams?.accessLiberation?.availableFields;

    const { RESIDENT, PROVIDER, SUPPLIER, DELIVERYMAN, PUBLIC_AGENT, DRIVER, EMPLOYEE, RELATIVE, AIRBNB, TENANT, OTHER } =
      this.condo.generalParams?.accessLiberation?.availableVisitorTypes;

    this.form.patchValue({
      geral: {
        maxDays,
        useShouldCall,
        visitorSelfRegistration,
        visitorSelfRegistrationFromQrCode,
        selfAttendanceForAutonomousConcierges,
        virtualKeyOfSelfAttendanceForAutonomousConcierges: virtualKeyOfSelfAttendanceForAutonomousConcierges || null,
        residentCanSearchAllVisitors,
        residentCanRegisterAccessLiberation,
        gateKeeperCanRegisterVisitorPermission,
        gateKeeperCanShareVisitorPermissionLink,
        visitorAccessLiberationDefaultTime: {
          startTime: startTime.toDate(),
          endTime: endTime.toDate()
        },
        isAbleToEdit,
        allowRegisteringGenericDocument,
        visitorCanCreateMultipleVisitors,
        residentCanCreateVisitorIdentificationLiberation
      },
      morador: {
        email,
        birthDate,
        phone,
        rg,
        cpf,
        picture,
        accessGroups,
        documentImage
      },
      visitante: {
        RESIDENT,
        PROVIDER,
        SUPPLIER,
        DELIVERYMAN,
        PUBLIC_AGENT,
        DRIVER,
        EMPLOYEE,
        RELATIVE,
        AIRBNB,
        TENANT,
        OTHER
      }
    });
  }

  setParams() {
    if (this.condo.params) {
      const condoParams = this.condo.params;

      for (const sectionKey in this.CONDO_SECTION_PARAMS) {
        const section = this.CONDO_SECTION_PARAMS[sectionKey];

        for (const key in section.params) {
          if (section.params.hasOwnProperty(key)) {
            const param = section.params[key];
            param.value = condoParams[param.paramName];
            param.originalValue = param.value === param.true;
            param.toggleValue = param.value === param.true;
          }
        }
      }
    }

    Object.entries((this.form.get('morador') as UntypedFormGroup).controls).forEach(([key, control]) => {
      control
        .get('available')
        .valueChanges.pipe(takeUntil(this.unsubscribe))
        .subscribe(value => {
          if (value) {
            control.get('required').enable();
          } else {
            control.get('required').setValue(false, { emmitEvent: false });
            control.get('required').disable();
          }
        });
    });

    this.form.get(['geral', 'gateKeeperCanRegisterVisitorPermission']).valueChanges.subscribe(value => {
      this.CONDO_SECTION_PARAMS.geral.params.gateKeeperCanShareVisitorPermissionLink.show = value;
    });

    this.getVirtualKeys();
  }

  getVirtualKeys() {
    this.status.setAsProcessing();

    const query: EcondosQuery = {
      $populate: [
        { path: 'camera', select: 'name' },
        { path: 'actuator', select: 'name' },
        { path: 'groupsCanTrigger', select: 'name icon' }
      ]
    };

    this.virtualKeyService
      .get(this.condo._id, query)
      .pipe(timeoutWith(20_000, ErrorBuilder.throwTimeoutError()))
      .subscribe({
        next: response => {
          this.virtualKeys = response.virtualKeys;
          this.status.setAsSuccess();
        },
        error: () => {
          this.status.setAsError();
        }
      });
  }

  handleSelectParamsSection(sectionKey: typeof this.selectedParamsSection) {
    this.selectedParamsSection = sectionKey;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  generateVisitorSelfRegistrationQrCode(condo: Condo) {
    const hostUrl = location.hostname;
    const modalTitle = `QR Code para Cadastro de ${this.condo.customLabels.visitor.singular || 'visitante'}`;
    const modalBodyText = `Apresente o QR Code abaixo para que o ${this.condo.customLabels.visitor.singular || 'visitante'} possa fazer seu cadastro.`;

    let qrData: string;

    if (hostUrl.includes('localhost')) {
      qrData = `http://localhost:8100/self-registration?condo=${this.condo._id}`;
    } else {
      qrData = `${this.themeService.activeTheme?.qrCodePageUrl || 'visitante.econdos.com.br'}/self-registration?condo=${this.condo._id}`;
    }

    const initialState = {
      condo,
      modalTitle,
      modalBodyText,
      qrData
    };

    this.modalService.show(ModalGenerateQrcodeComponent, { initialState });
  }

  generateSelfAttendanceForAutonomousConciergesQrCode(condo: Condo) {
    const savedToken = localStorage.getItem('autonomousConcierges');

    if (savedToken) {
      // Se o token já existir, gera o QR code com o link
      this.generateQrDataForAutonomousConcierges(condo, savedToken);
    } else {
      const user = {
        firstName: 'John',
        lastName: 'Doe',
        email: this.generateRandomEmail(),
        password: '123'
      };

      this.condoService.addUserToCondo(this.condo.id, user).subscribe({
        next: () => {
          const token = btoa(`${user.email}:${user.password}`);
          localStorage.setItem('autonomousConcierges', token);

          this.generateQrDataForAutonomousConcierges(condo, token);
        },
        error: (error: any) => {
          this.toastr.error('Erro ao adicionar o morador ao condomínio.');
          console.error('Error:', error);
        }
      });
    }
  }

  // Função para gerar os dados do QR code
  generateQrDataForAutonomousConcierges(condo: Condo, token: string) {
    const hostUrl = location.hostname;
    const modalTitle = `QR Code para auto atendimento de ${this.condo.customLabels.visitor.singular || 'visitante'}`;
    const modalBodyText = `Apresente o QR Code abaixo para que o ${this.condo.customLabels.visitor.singular || 'visitante'} possa fazer seu auto atendimento.`;

    let qrData: string;
    if (hostUrl.includes('localhost')) {
      qrData = `http://127.0.0.1:5173/self-call?condoId=${condo._id}&token=${token}`;
    } else {
      qrData = `https://autocadastro.econdos.com.br/self-call?condoId=${condo._id}&token=${token}`;
    }

    const initialState = {
      condo,
      modalTitle,
      modalBodyText,
      qrData
    };

    this.modalService.show(ModalGenerateQrcodeComponent, { initialState });
  }

  //Vai ser usado até pensar em uma forma melhor de validar o token
  generateRandomEmail(): string {
    const randomString = Math.random().toString(36).substring(2, 8);
    const domain = 'example.com';
    return `${randomString}@${domain}`;
  }

  submit() {
    if (this.form.valid) {
      // Para retrocompatibilidade, vamos fazer o antigo campo de obrigatoriedade de documento ser true se RG ou CPF for obrigatório
      const residentMustRegisterVisitorDocument = this.form.value.morador?.cpf?.required || this.form.value.morador?.rg?.required || false;

      const availableVisitorTypes = {
        ...this.condo?.generalParams?.accessLiberation?.availableVisitorTypes,
        ...this.form.value.visitante
      };

      const startTimeHour = String(moment(this.form.value.geral?.visitorAccessLiberationDefaultTime?.startTime).hour()).padStart(2, '0');
      const startTimeMinute = String(moment(this.form.value.geral?.visitorAccessLiberationDefaultTime?.startTime).minute()).padStart(
        2,
        '0'
      );
      const endTimeHour = String(moment(this.form.value.geral?.visitorAccessLiberationDefaultTime?.endTime).hour()).padStart(2, '0');
      const endTimeMinute = String(moment(this.form.value.geral?.visitorAccessLiberationDefaultTime?.endTime).minute()).padStart(2, '0');

      const start = moment().hour(parseInt(startTimeHour)).minute(parseInt(startTimeMinute));
      const end = moment().hour(parseInt(endTimeHour)).minute(parseInt(endTimeMinute));

      const availableFields = { ...this.form.value.morador };
      if (end.isBefore(start)) {
        this.toastr.warning('O horário de término não pode ser menor que o horário de início. ');
      } else {
        const gateKeeperCanShareVisitorPermissionLink = this.form.value.geral.gateKeeperCanRegisterVisitorPermission
          ? this.form.value.geral.gateKeeperCanShareVisitorPermissionLink
          : false;
        const accessLiberation = {
          ...this.condo.generalParams.accessLiberation,
          maxDays: this.form.value.geral.maxDays || 180,
          residentMustRegisterVisitorDocument,
          useShouldCall: this.form.value.geral.useShouldCall,
          visitorSelfRegistration: this.form.value.geral.visitorSelfRegistration || false,
          visitorSelfRegistrationFromQrCode: this.form.value.geral.visitorSelfRegistrationFromQrCode || false,
          selfAttendanceForAutonomousConcierges: this.form.value.geral.selfAttendanceForAutonomousConcierges || false,
          virtualKeyOfSelfAttendanceForAutonomousConcierges: this.form.value.geral.virtualKeyOfSelfAttendanceForAutonomousConcierges,
          availableFields,
          residentCanSearchAllVisitors: this.form.value.geral.residentCanSearchAllVisitors,
          residentCanRegisterAccessLiberation: this.form.value.geral.residentCanRegisterAccessLiberation,
          gateKeeperCanRegisterVisitorPermission: this.form.value.geral.gateKeeperCanRegisterVisitorPermission,
          gateKeeperCanShareVisitorPermissionLink,
          availableVisitorTypes,
          visitorAccessLiberationDefaultTime: {
            startTime: `${startTimeHour}:${startTimeMinute}`,
            endTime: `${endTimeHour}:${endTimeMinute}`
          },
          isAbleToEdit: this.form.value.geral.isAbleToEdit,
          allowRegisteringGenericDocument: this.form.value.geral.allowRegisteringGenericDocument,
          visitorCanCreateMultipleVisitors: this.form.value.geral.visitorCanCreateMultipleVisitors,
          residentCanCreateVisitorIdentificationLiberation: this.form.value.geral.residentCanCreateVisitorIdentificationLiberation
        };

        const generalParams = {
          ...this.condo.generalParams,
          accessLiberation
        };

        this.status.setAsProcessing();

        this.condoService
          .updateCondo(this.condo._id, { generalParams })
          .pipe(timeout(10000), takeUntil(this.unsubscribe))
          .subscribe({
            next: () => {
              this.condo.generalParams.accessLiberation = accessLiberation;

              this.utilService.saveLocalCondo(this.condo);

              const updatedUser = this.utilService.getLocalUser();
              updatedUser.defaultCondo.generalParams.accessLiberation = accessLiberation;
              this.sessionService.setUser(updatedUser);
              this.toastr.success('Parâmetros de liberação de acesso atualizados com sucesso');
              this.status.setAsSuccess();
              this.bsModalRef.hide();
            },
            error: () => {
              this.status.setAsError();
              swal({
                type: 'error',
                text: 'Não foi possível atualizar os parâmetros, tente novamente'
              });
            }
          });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }
}
