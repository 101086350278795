import { CdkStepper } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { faFingerprint, faIdBadge, faLock, faQrcode, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { plateIcon, rfControllerIcon } from '../../../../assets/svg/custom-icons';
import { Condo } from '@api/model/condo';
import { File } from '@api/model/file';
import { UtilService } from '../../../services/util.service';
import { debounceTime, filter, map, retry, startWith, switchMap, take, takeUntil, tap, timeout } from 'rxjs/operators';
import { combineLatest, from, lastValueFrom, Observable, Subject, throwError } from 'rxjs';
import { decimalToSerial, decimalToWiegand, serialToDecimal, serialToWiegand, wiegandToDecimal, wiegandToSerial } from '@api/utils';
import { CondoVehicle } from '@api/model/condo.vehicle';
import { CreateDeviceBaseStepperComponent } from '../create-device-base-stepper/create-device-base-stepper.component';
import { ThemeService } from '../../../theme';
import { conditionalValidator, maxDateValidator, minDateValidator } from '@api/util/validators';
import { CapabilitiesService } from '@api/serviceV2/capabilities.service';
import { Capabilities } from '@econdos/econdos-base-sdk';
import { Device } from '@api/model/hardware/device';
import { HARDWARES } from '@api/model/hardware/hardware-constants';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { ActuatorService } from '@api/service/hardware/actuator.service';
import { HardwareSocketService } from '../../../services/hardware-socket.service';
import { BiometricHandFinger } from '../../../components/biometric-hand/biometric-hand.component';
import { Actuator } from '@api/model/hardware/actuator';
import { HardwareDeviceServiceV2 } from '@api/serviceV2/hardware/hardware-device.service';
import { SdkService } from '@api/service/hardware/sdk.service';
import { PrintContentService } from '../../../services/print-content.service';
import { EcondosQuery } from '@api/model/query';
import { FileService } from '@api/service/file.service';
import * as moment from 'moment';

interface IActuator extends Actuator {
  otherActuatorsIds?: string[];
}

@Component({
  selector: 'app-create-device-step',
  templateUrl: 'create-device-step.component.html',
  styleUrls: ['create-device-step.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: CreateDeviceStepComponent }]
})
export class CreateDeviceStepComponent implements OnInit, OnDestroy {
  @ViewChild('cdkStepper') stepper: CreateDeviceBaseStepperComponent;

  deviceId = '';
  deviceTypes = [];

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  onStepChange: EventEmitter<number> = new EventEmitter<0>();

  @Input()
  isLinear = false;

  @Input()
  isEditable = true;

  isOnlyHikvisionEnabled = false;

  form: UntypedFormGroup;

  condo: Condo;

  isWhitelabel = false;

  capabilities: Capabilities;

  private unsubscribe: Subject<void> = new Subject();

  wiegand = '';
  serial = '';
  decimal = '';

  isDependentsDisabled = false;

  qrdata = '';

  fingersOptions: Array<{ value: BiometricHandFinger; label: string }> = [
    { value: 'LEFT_INDEX_FINGER', label: 'Dedo indicador esquerdo' },
    { value: 'LEFT_LITTLE_FINGER', label: 'Dedo mindinho esquerdo' },
    { value: 'LEFT_MIDDLE_FINGER', label: 'Dedo médio esquerdo' },
    { value: 'LEFT_RING_FINGER', label: 'Dedo anelar esquerdo' },
    { value: 'LEFT_THUMB', label: 'Polegar esquerdo' },
    { value: 'RIGHT_INDEX_FINGER', label: 'Dedo indicador direito' },
    { value: 'RIGHT_LITTLE_FINGER', label: 'Dedo mindinho direito' },
    { value: 'RIGHT_MIDDLE_FINGER', label: 'Dedo médio direito' },
    { value: 'RIGHT_RING_FINGER', label: 'Dedo anelar direito' },
    { value: 'RIGHT_THUMB', label: 'Polegar direito' }
  ];

  condoActuators: IActuator[] = [];
  remoteActuator: UntypedFormControl = new UntypedFormControl(null);

  getTemplate$: Observable<string>;

  personAutocompleteCustomQuery: EcondosQuery;

  // Retun steps
  get formArray(): AbstractControl {
    return this.form?.get('steps');
  }

  // Step 1
  get deviceTypeForm(): AbstractControl {
    return this.formArray?.get('0');
  }

  // Step 2
  get identificationForm(): AbstractControl {
    return this.formArray?.get('1');
  }

  // Step 3
  get deviceForm(): AbstractControl {
    return this.formArray?.get('2');
  }

  get settingsForm(): AbstractControl {
    return this.formArray?.get('3');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private themeService: ThemeService,
    private capabilitiesService: CapabilitiesService,
    private actuatorService: ActuatorService,
    private hardwareSocketService: HardwareSocketService,
    private toastr: ToastrService,
    private utilService: UtilService,
    private hadwareDeviceService: HardwareDeviceServiceV2,
    private sdkService: SdkService,
    private printContentService: PrintContentService,
    private toastrService: ToastrService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    this.condo = this.utilService.getLocalCondo();
    this.isOnlyHikvisionEnabled = this.condo?.hikvision?.enabled && !this.condo?.bravas?.enabled && !this.condo?.xpe?.enabled;
    this.isWhitelabel = this.themeService.activeTheme?.name !== 'econdos';
    this.capabilities = this.capabilitiesService.getCondoCapabilities(this.condo);
    this.isDependentsDisabled = this.condo.isDependentsDisabled();

    this.setDeviceTypesByCapabilities(this.capabilities);

    this.form = this.fb.group({
      steps: this.fb.array([
        this.fb.group({
          type: ['', Validators.compose([Validators.required])]
        }),
        this.fb.group({
          personType: ['RESIDENT', Validators.required],
          person: [null, Validators.required],
          residence: [null, Validators.required],
          vehicle: [null],
          picture: [null]
        }),
        this.fb.group({
          serial: [
            '',
            conditionalValidator(() => {
              const deviceType = this.deviceTypeForm.get('type').value;
              return deviceType !== 'FACIAL' && deviceType !== 'BM';
            }, Validators.required)
          ],
          serialType: ['SERIAL'],
          picture: [
            null,
            conditionalValidator(() => {
              const deviceType = this.deviceTypeForm.get('type').value;
              return deviceType === 'FACIAL';
            }, Validators.required)
          ],
          finger: [
            '',
            conditionalValidator(() => {
              const deviceType = this.deviceTypeForm.get('type').value;
              return deviceType === 'BM';
            }, Validators.required)
          ],
          template: [
            '',
            conditionalValidator(() => {
              const deviceType = this.deviceTypeForm.get('type').value;
              return deviceType === 'BM';
            }, Validators.required)
          ]
        }),
        this.fb.group({
          panic: [false],
          enabled: [true],
          credits: [undefined],
          accessGroups: [[]],
          obs: [''],
          validFrom: [''],
          validFromTime: [undefined],
          validUntil: [''],
          validUntilTime: [undefined]
        })
      ])
    });

    this.settingsForm
      .get('validUntil')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.settingsForm.get('validUntil').setValidators([
          maxDateValidator('2050-12-31'),
          minDateValidator(moment(new Date()).format('YYYY-MM-DD')),
          (formControl: FormControl<string>) => {
            if (!formControl.value) {
              return;
            }

            const parent = formControl.parent as FormGroup<any>;
            if (!parent?.controls) {
              return;
            }

            const validFromString = `${parent.controls.validFrom.value} ${parent.controls.validFromTime.value}`;
            const validFromDate = moment(validFromString, 'YYYY-MM-DD HH:mm').toISOString();
            const validUntilString = `${formControl.value} ${parent.controls.validUntilTime.value}`;
            const validUntilDate = moment(validUntilString, 'YYYY-MM-DD HH:mm').toISOString();
            if (moment(validFromDate).isSameOrAfter(validUntilDate)) {
              return { startTimeBeforeEndTime: true };
            }
          },
          conditionalValidator(() => this.settingsForm.get('validFrom').value, Validators.required)
        ]);
      });
    this.settingsForm
      .get('validFromTime')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.settingsForm.get('validUntil').updateValueAndValidity({ emitEvent: false });
      });
    this.settingsForm
      .get('validFrom')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.settingsForm.get('validUntil').updateValueAndValidity({ emitEvent: false });
      });
    this.settingsForm
      .get('validUntilTime')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.settingsForm.get('validUntil').updateValueAndValidity({ emitEvent: false });
      });

    this.deviceTypeForm
      .get('type')
      .valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500))
      .subscribe(() => {
        const formGroup = this.deviceForm as UntypedFormGroup;
        Object.keys(formGroup.controls).forEach(key => formGroup.get(key).updateValueAndValidity({ emitEvent: false }));
      });

    this.identificationForm
      .get('personType')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(v => {
        this.identificationForm.get('person').reset();
      });

    this.identificationForm.get('person').disable();
    this.identificationForm.get('vehicle').disable();

    this.identificationForm
      .get('residence')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(value => {
        if (value) {
          this.identificationForm.get('person').enable();
          this.identificationForm.get('vehicle').enable();

          const personType = this.identificationForm.get('personType').value;

          switch (personType) {
            case 'RESIDENT':
              this.personAutocompleteCustomQuery = {
                $limit: 50,
                $or: [{ residencesUser: value._id }, { residencesVoter: value._id }]
              };
              break;

            case 'DEPENDENT':
              this.personAutocompleteCustomQuery = {
                $limit: 50,
                residence: value._id
              };
              break;
          }
        } else {
          this.identificationForm.get('person').disable();
          this.identificationForm.get('vehicle').disable();
          this.personAutocompleteCustomQuery = null;
        }
      });

    this.identificationForm
      .get('person')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((person: any) => {
        if (person?.picture) {
          this.identificationForm.get('picture').setValue(person.picture);
          this.deviceForm.get('picture').setValue(person.picture);
        } else {
          this.identificationForm.get('picture').setValue(null);
          this.deviceForm.get('picture').setValue(null);
        }
      });

    this.identificationForm
      .get('picture')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe),
        filter(p => !!p),
        filter(() => this.deviceTypeForm.get('type').value === 'FACIAL')
      )
      .subscribe((picture: File) => {
        this.deviceForm.get('picture').setValue(picture);
      });

    this.identificationForm
      .get('vehicle')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe((vehicle: CondoVehicle) => {
        const deviceType = this.deviceTypeForm.get('type').value;
        if (deviceType === 'VEHICLE_PLATE') {
          if (vehicle && vehicle.plate) {
            this.deviceForm.get('serial').setValue(vehicle.plate);
          } else {
            this.deviceForm.get('serial').setValue('');
          }
        }
      });

    this.deviceForm
      .get('serial')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe),
        filter(() => this.deviceTypeForm.get('type').value !== 'VEHICLE_PLATE')
      )
      .subscribe(serial => {
        this.deviceForm.get('serial').setValue(serial.replace(/[^A-Fa-f0-9]/g, ''), { emitEvent: false });

        if (this.deviceTypeForm.get('type').value === 'QR') {
          this.qrdata = serial;
        }
      });

    /**
     * Para atualizar ambos os componentes de biometria
     * Fonte: https://stackoverflow.com/a/57320504
     */
    this.deviceForm
      .get('finger')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(finger => {
        this.deviceForm.get('finger').setValue(finger, { onlySelf: true, emitEvent: false, emitModelToViewChange: true });
      });

    combineLatest([
      this.deviceTypeForm.get('type').valueChanges.pipe(startWith('')),
      this.deviceForm.get('serialType').valueChanges.pipe(startWith(this.deviceForm.get('serialType').value || '')),
      this.deviceForm.get('serial').valueChanges.pipe(startWith(''))
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([deviceType, serialType, serial]) => {
        if (deviceType === 'CT' || deviceType === 'RF') {
          serial = serial.replace(/[^A-Fa-f0-9]/g, '');
          try {
            switch (serialType) {
              case 'WIEGAND': {
                this.wiegand = serial;
                this.decimal = wiegandToDecimal(serial);
                this.serial = wiegandToSerial(serial);
                break;
              }
              case 'DECIMAL': {
                this.decimal = serial;
                this.wiegand = decimalToWiegand(serial);
                this.serial = decimalToSerial(serial);
                break;
              }
              case 'SERIAL': {
                if (serial.length >= 6) {
                  this.serial = serial;
                  this.decimal = serialToDecimal(serial);
                  this.wiegand = serialToWiegand(serial);
                }
                break;
              }
            }
          } catch (e) {
            this.wiegand = 'Inválido';
            this.serial = 'Inválido';
            this.decimal = 'Inválido';
          }
        } else if (deviceType === 'SN') {
          this.deviceForm.get('serialType').setValue('DECIMAL', { emitEvent: false });
          this.deviceForm.get('serial').setValidators([Validators.minLength(4), Validators.maxLength(10), Validators.required]);
          this.deviceForm.updateValueAndValidity();
        } else if (deviceType === 'VEHICLE_PLATE') {
          this.deviceForm.get('serialType').setValue('DECIMAL', { emitEvent: false });
          this.deviceForm.get('serial').setValidators([Validators.minLength(3), Validators.maxLength(7), Validators.required]);
          this.deviceForm.updateValueAndValidity();
        }
      });

    this.loadActuators();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  next() {
    this.stepper.next();
    this.onStepChange.emit(this.stepper.selectedIndex);
  }

  previous() {
    this.stepper.previous();
    this.onStepChange.emit(this.stepper.selectedIndex);
  }

  setCurrentStep(step) {
    this.stepper.selectedIndex = step;
    this.onStepChange.emit(this.stepper.selectedIndex);
  }

  handleStepChange(step: number) {
    this.onStepChange.emit(step);
  }

  setDeviceTypesByCapabilities(capabilities: Capabilities) {
    if (capabilities.device.card) {
      this.deviceTypes.push({ type: 'CT', label: 'Cartão / TAG', icon: faIdBadge, size: '3x' });
    }
    if (capabilities.device.rf) {
      this.deviceTypes.push({ type: 'RF', label: 'Controle', icon: rfControllerIcon, size: '3x' });
    }

    if (capabilities.device.fingerprint) {
      this.deviceTypes.push({ type: 'BM', label: 'Biometria', icon: faFingerprint, size: '3x' });
    }

    if (capabilities.device.facial) {
      this.deviceTypes.push({ type: 'FACIAL', label: 'Facial', icon: faUserCircle, size: '3x' });
    }

    if (capabilities.device.password) {
      this.deviceTypes.push({ type: 'SN', label: 'Senha', icon: faLock, size: '3x' });
    }

    if (capabilities.device.qrcode) {
      this.deviceTypes.push({ type: 'QR', label: 'QR Code', icon: faQrcode, size: '3x' });
    }

    if (capabilities.device.vehiclePlate) {
      this.deviceTypes.push({ type: 'VEHICLE_PLATE', label: 'Placa veicular', icon: plateIcon, size: '4x' });
    }
  }

  async setValueFromDevice(device: Device) {
    const {
      _id,
      type,
      serial = '',
      serialType = '',
      panic,
      enabled,
      obs = '',
      accessGroups = [],
      credits = '',
      finger = '',
      validUntil = '',
      validFrom = ''
    } = device;

    this.deviceId = _id;

    let vehicle = null;
    let residence = null;
    let person = null;
    let personType = '';
    let picture = null;

    if (device.owner?.residence) {
      residence = device.owner.residence;
    }
    if (device.owner?.condoVehicle) {
      vehicle = device.owner.condoVehicle;
    }

    if (device.owner.user) {
      person = device.owner.user;
      personType = 'RESIDENT';
    } else if (device.owner?.condoContact) {
      person = device.owner.condoContact;
      personType = device.owner.condoContact.type || 'VISITOR';
    } else if (device.owner?.dependent) {
      personType = 'DEPENDENT';
      person = device.owner.dependent;
    }
    if (device.owner?.picture || person?.picutre) {
      picture = device.owner?.picture || person?.picutre;
    }

    let template = '';

    if (type === 'BM') {
      try {
        const response = await this.hadwareDeviceService.getDeviceTemplate(this.condo._id, this.deviceId).pipe(retry(3)).toPromise();
        template = response.template;
      } catch (err) {
        console.log(err);
        this.toastr.error('Não foi possível carregar o template da biometria.');
      }
    }

    const validUntilTime = validUntil ? moment(validUntil).format('HH:mm') : null;
    const validFromTime = validFrom ? moment(validFrom).format('HH:mm') : null;
    const validUntilDate = validUntil ? moment(validUntil).format('YYYY-MM-DD') : null;
    const validFromDate = validFrom ? moment(validFrom).format('YYYY-MM-DD') : null;

    const typeForm = { type };
    const identificationForm = { person, personType, residence, vehicle, picture };
    const deviceForm = { serial, serialType, picture, finger, template };
    const rulesForm = {
      panic,
      enabled,
      accessGroups,
      credits,
      obs,
      validUntil: validUntilDate,
      validFrom: validFromDate,
      validUntilTime,
      validFromTime
    };
    const values = [typeForm, identificationForm, deviceForm, rulesForm];
    this.form.patchValue({ steps: [...values] });
    // O valor do person é feito posteriormente para que o value changes do personType não resete o valor do person
    this.identificationForm.get('person').patchValue(person, { emitEvent: false });
  }

  setValueFromStorageUser(storageUser) {
    const { residence, user } = storageUser;

    if (residence) {
      this.identificationForm.get('residence').setValue({
        identification: residence.identification,
        block: residence?.block,
        number: residence?.number,
        lot: residence?.lot,
        _id: residence._id
      });
    }

    if (user) {
      this.identificationForm.get('person').setValue(user);

      const residences = storageUser.user.getResidences();
      if (residences && residences.length && !residence) {
        this.identificationForm.get('residence').setValue({
          identification: residences[0].identification,
          block: residences[0]?.block,
          number: residences[0]?.number,
          lot: residences[0]?.lot,
          _id: residences[0]._id
        });
      }

      if (user.picture) {
        this.identificationForm.get('picture').setValue(user.picture);
        this.deviceForm.get('picture').setValue(user.picture);
      }
    }
  }

  getValueAsDevice() {
    const [typeForm, identificationForm, deviceForm, rulesForm] = this.formArray.value;
    const { type } = typeForm;
    const { person, personType, residence, vehicle } = identificationForm;
    const { serial, serialType, picture, finger, template } = deviceForm;
    const { panic, enabled, accessGroups, credits, obs, validUntil, validFrom, validUntilTime, validFromTime } = rulesForm;

    const validFromString = `${validFrom} ${validFromTime}`;
    const validFromDate = moment(validFromString, 'YYYY-MM-DD HH:mm').toISOString();

    const validUntilString = `${validUntil} ${validUntilTime}`;
    const validUntilDate = moment(validUntilString, 'YYYY-MM-DD HH:mm').toISOString();

    const device: any = {
      type,
      hardware: HARDWARES.ANY,
      accessGroups,
      serial,
      serialType,
      panic,
      enabled,
      credits,
      obs,
      owner: { residence }
    };
    if (type === 'BM') {
      device.finger = finger;
      device.template = template;
    }
    if (personType === 'RESIDENT') {
      device.owner.user = person;
      device.accessType = 'RESIDENT';
    } else if (personType === 'VISITOR') {
      device.owner.condoContact = person;
      device.accessType = person.type || 'VISITOR';
    } else if (personType === 'DEPENDENT') {
      device.owner.dependent = person;
      device.accessType = 'RESIDENT';
    }

    if (vehicle) {
      device.owner.condoVehicle = vehicle;
    }

    if (picture) {
      device.owner.picture = picture;
    } else if (person.picture) {
      device.owner.picture = person.picture;
    }
    if (validUntil) {
      device.validUntil = validUntilDate;
    }
    if (validFrom) {
      device.validFrom = validFromDate;
    }
    return device;
  }

  handleCannotGoToStep(step: number) {
    this.toastr.error('Finalize a etapa atual antes de avançar');
  }

  async collectSerial() {
    const hardware = [];
    if (this.condo.isHikvisionEnabled()) {
      hardware.push(HARDWARES.HIKVISION);
    }

    if (this.condo.isBravasEnabled()) {
      hardware.push(HARDWARES.BRAVAS);
    }

    const actuators = await lastValueFrom(
      this.actuatorService.getActuators(this.condo._id, { hardware: { $in: hardware } }).pipe(
        map(v => v.actuators),
        timeout(10000),
        retry(3)
      )
    );
    if (!actuators || !actuators.length) {
      swal({
        type: 'error',
        title: 'Equipamentos não cadastrados',
        text: 'Não existem equipamentos cadastrado. Cadastre um acionador antes de realizar a coleta remota'
      });
      return;
    }
    let selectedActuator;
    if (actuators.length > 1) {
      const inputOptions: any = actuators.reduce(
        (acc, curr) => {
          acc[curr._id] = curr.name;
          return acc;
        },
        { ALL: 'Todos' }
      );

      const actuatorId = await swal({
        inputOptions,
        title: 'Equipamento',
        text: 'Selecione o equipamento desejado para a coleta',
        input: 'select',
        inputPlaceholder: 'Selecione',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#32DB64',
        cancelButtonColor: '#f53d3d',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        inputValidator: async res => {
          if (res) {
            await Promise.resolve();
          } else {
            return Promise.reject('Selecione um equipamento!');
          }
        }
      });
      if (actuatorId === 'ALL') {
        selectedActuator = actuatorId;
      } else {
        selectedActuator = actuators.find(a => a._id === actuatorId);
      }
    } else {
      selectedActuator = actuators[0];
    }

    const destroy$ = new Subject();

    this.hardwareSocketService.onData$
      .pipe(
        timeout(60000),
        filter(
          ({ condoId, hardwareEvent }) =>
            condoId === this.condo._id &&
            hardwareEvent &&
            hardwareEvent.eventType === 'OTHER' &&
            hardwareEvent.serial &&
            hardwareEvent.label?.includes('Dispositivo não cadastrado')
        ),
        filter(
          data => selectedActuator === 'ALL' || (data.hardwareEvent.actuator?._id || data.hardwareEvent.actuator) === selectedActuator._id
        ),
        map(res => ({ ...res.hardwareEvent })),
        map(res => (res && res.serial) || ''),
        takeUntil(destroy$),
        take(1),
        tap(() => {
          swal.clickConfirm();
        })
      )
      .subscribe(
        serial => {
          this.toastr.success(`Código ${serial} lido com sucesso`);
          this.deviceForm.get('serial').setValue(serial);
          destroy$.next(null);
          destroy$.complete();
        },
        err => {
          swal({
            type: 'error',
            title: 'Erro na leitura',
            text: 'Não foi possível realizar a leitura do cartão'
          });
          destroy$.next(null);
          destroy$.complete();
          console.log(err);
        }
      );

    swal({
      type: 'info',
      title: 'Aguardando leitura',
      text: `Passe o cartão na leitora ${selectedActuator.name || ''}`,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#f53d3d',
      cancelButtonText: 'Cancelar'
    }).catch(e => {
      console.log(e);
      destroy$.next(null);
      destroy$.complete();
    });
  }

  async generateQrcode() {
    const qrcode = await this.sdkService.generateQrcode();
    this.deviceForm.get('serial').setValue(qrcode);
  }

  print(elementId: string) {
    const printContents = document.getElementById(elementId).innerHTML;
    this.printContentService.print(printContents);
  }

  loadActuators() {
    this.actuatorService
      .getActuators(this.condo._id, { $sort: 'name' })
      .pipe(retry(3))
      .subscribe({
        next: actuatorsResponse => {
          const actuators = [];
          actuatorsResponse.actuators.forEach(actuator => {
            /*
              Verifica se existem outros acionadores do tipo ID Box com mesmo host, se sim, só adiciona uma vez no array de actuators
              Necessário porque o ID Box pode ter diversas portas para serem acionadas
            */
            if (actuator.type === 'iDBox') {
              const index = actuators.findIndex(
                a =>
                  (a.port && a.host && a.port === actuator.port && a.host === actuator.host) ||
                  (a.port2 && a.host2 && a.port2 === actuator.port2 && a.host2 === actuator.host2)
              );
              if (index === -1) {
                actuators.push(actuator);
              } else {
                let name = actuators[index].name;
                name += `, ${actuator.name}`;
                actuators[index].name = name;
                if (!actuators[index].otherActuatorsIds) {
                  actuators[index].otherActuatorsIds = [];
                }
                actuators[index].otherActuatorsIds.push(actuator._id);
              }
            } else {
              actuators.push(actuator);
            }
          });
          this.condoActuators = actuators;
        },
        error: err => {
          // TODO tratar casos de erro
          console.log(err);
        }
      });
  }

  async readFingerPrintTemplate() {
    const finger = this.deviceForm.get('finger').value;
    const actuator = this.remoteActuator.value as Actuator;

    if (!actuator || !finger) {
      this.toastr.warning('Selecione um dedo e leitora de cadastro.');
      this.deviceForm.markAllAsTouched();
      this.remoteActuator.markAsTouched();
      return;
    }

    const unsubscribe$ = new Subject();

    swal({
      title: 'Dedo 1',
      text: 'Coloque o dedo na leitora',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      imageUrl: 'assets/svg/fingerprint.svg',
      imageWidth: 200,
      imageHeight: 200
    }).catch(() => {
      unsubscribe$.next(null);
      unsubscribe$.complete();
    });

    from(this.sdkService.captureFingerprint({ actuator, finger }))
      .pipe(take(1), takeUntil(unsubscribe$))
      .subscribe({
        next: result => {
          this.deviceForm.get('template').setValue(result.template);
          swal.close();
        },
        error: err => {
          console.log(err);

          swal.close();

          swal({
            type: 'error',
            title: 'Erro',
            text: 'Ocorreu um erro ao tentar capturar a biometria. Tente novamente, por favor.'
          }).catch(swal.noop);
        }
      });
  }

  async collectFace() {
    if (!this.isOnlyHikvisionEnabled) {
      return;
    }

    this.actuatorService
      .getActuators(this.condo._id, { hardware: 'HIKVISION' })
      .pipe(
        timeout(10000),
        switchMap(({ actuators }) => {
          if (!actuators || !actuators.length) {
            return throwError('Actuators not found');
          }
          const actuatorsMap = actuators.reduce((acc, actuator) => {
            acc[actuator._id] = actuator;
            return acc;
          }, {});
          const inputOptions: any = actuators.reduce((acc, actuator) => {
            acc[actuator._id] = actuator.name;
            return acc;
          }, {});
          return from(
            swal({
              type: 'question',
              title: 'Selecionar equipamento',
              text: `Selecione o equipamento que você deseja utilizar para capturar a face da pessoa`,
              input: 'select',
              inputOptions,
              inputPlaceholder: 'Selecione o equipamento',
              inputValidator: value => {
                return new Promise((resolve, reject) => {
                  if (value) {
                    resolve();
                  } else {
                    reject('Selecione um equipamento para o registro e tente novamente!');
                  }
                });
              },
              showCancelButton: true,
              confirmButtonText: 'Capturar',
              cancelButtonText: 'Cancelar',
              reverseButtons: true
            }).catch(() => swal.noop())
          ).pipe(
            switchMap(actuatorId => {
              if (!actuatorId) {
                return throwError('Canceled by user');
              }
              const actuator = actuatorsMap[actuatorId];

              const destroyed$: Subject<boolean> = new Subject<boolean>();
              swal({
                title: 'Registrando imagem...',
                text: 'A pessoa deve ficar na frente do equipamento',
                type: 'info',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false
              }).catch(e => {
                destroyed$.next(true);
                if (e?.includes('cancel')) {
                  return throwError('Canceled by user');
                }
              });
              return from(this.sdkService.captureFaceBase64({ actuator })).pipe(
                takeUntil(destroyed$),
                timeout(60000),
                map(({ data }) => `data:image/jpeg;base64,${data}`),
                switchMap(image => this.fileService.uploadBase64(image)),
                map(([file]) => file)
              );
            })
          );
        })
      )
      .subscribe(
        (img: File) => {
          this.identificationForm.get('picture').setValue(img);
          swal.clickConfirm();
          this.toastrService.success('Facial capturada com sucesso');
        },
        err => {
          swal.close();
          const msg = err?.message || err;
          if (!msg?.includes('Canceled by user')) {
            swal({
              type: 'error',
              title: 'Ops...',
              text: (err.message || err)?.includes('Actuators not found')
                ? 'Não existem acionadores cadastrados. Antes de cadastrar uma facial é necessário você cadastrar os acionadores.'
                : 'Não foi possível realizar a captura da face. Verifique a conexão com o equipamento e tente novamente.'
            });
          }
        }
      );
  }
}
