import { Injectable } from '@angular/core';
import { Condo } from '../model/condo';
import { User } from '../model/user';
import { HttpService } from '../../services/http.service';
import { ConstantService } from '../../services/constant.service';
import { ResidenceBuilder } from '../model/residence/residence.builder';
import { Residence } from '../model/interface/residence';
import { map } from 'rxjs/operators';
import { CondoUser } from '../model/condo-user';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginLog } from '../model/login-log';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { downloadDataInChunks } from '@api/utils';
import { ContactID } from '@api/model/contact/contact.id';
import { IImportUserRowData } from '@api/model/import-row/users';

interface IncontrolConfig {
  host?: string;
  port?: number;
  host2?: string;
  port2?: number;
  username?: string;
  password?: string;
  enabled?: boolean;
}

export interface UpdateUserRoles {
  setAsUser: boolean;
  setAsJanitor: boolean;
  setAsGatekeeper: boolean;
  setAsAdmin: boolean;
  setAsOwner: boolean;
}

@Injectable()
export class CondoService {
  protected endPoint;

  constructor(
    protected http: HttpService,
    protected constantService: ConstantService
  ) {
    this.http = http;
    this.endPoint = `${this.constantService.getEndpoint()}condos/`;
  }

  /**
   * @deprecated Use getCondos instead.
   */
  getAllCondos(queryString?: string): Observable<Condo[]> {
    return this.http.get(this.endPoint + (queryString || '')).pipe(map((condos: Array<any>) => condos.map(condo => new Condo(condo))));
  }

  getCondos(params: EcondosQuery = {}): Observable<{ count: number; condos: Condo[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .getWithFullResponse(`${this.endPoint}`, {
        params: httpParams
      })
      .pipe(map((res: any) => ({ count: res.headers.get('count'), condos: (res.body || []).map(c => new Condo(c)) })));
  }

  createCondo(condo: any) {
    return this.http.post(this.endPoint, condo);
  }

  deleteCondo(condoId: string) {
    return this.http.delete(this.endPoint + condoId);
  }

  getCondoById(condoId: string | number, queryString?: string): Observable<Condo> {
    return this.http.get(this.endPoint + condoId + (queryString || '')).pipe(map(c => new Condo(c)));
  }

  updateCondo(condoId: string, condo: any) {
    return this.http.put(this.endPoint + condoId, condo);
  }

  updateUserCondoResidenceAttributes(condoId: string, userId: string, condoResidenceAttributes: any) {
    return this.http.put(this.endPoint + condoId + '/users/' + userId + '/condoResidenceAttributes', condoResidenceAttributes);
  }

  subscribeCondo(condoId: string, subscribptionData: CondoSubscription) {
    return this.http.put(this.endPoint + condoId + '/subscribe', subscribptionData);
  }

  getSubscriptionPrices(condoId: string): Observable<{ minimumPrice: number; defaultPrice: number }> {
    return this.http.get(this.endPoint + condoId + '/prices') as any;
  }

  /** @Deprecated Use getCondoResidents instead */
  getUsersFromCondo(condoId: string, queryString?: string): Observable<User[]> {
    return this.http
      .get(this.endPoint + condoId + '/users' + (queryString || ''))
      .pipe(map((users: any) => users.map(user => new User(user))));
  }

  /** @Deprecated Use getCondoResidents instead */
  // New method to get condo users, that area different from system user
  getCondoUsers(condoId: string, queryParams?: Object): Observable<CondoUser[]> {
    let params = new HttpParams();
    for (const key of Object.keys(queryParams)) {
      params = params.set(key, queryParams[key]);
    }
    return this.http.get(`${this.endPoint}${condoId}/users`, { params }).pipe(map((users: any) => users.map(user => new CondoUser(user))));
  }

  getCondoResidents(condoId: string, query: EcondosQuery = {}): Observable<{ count: number; users: User[] }> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = {
      headers: new HttpHeaders(),
      params: params,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/users`, options).pipe(
      map((res: any) => {
        if (query.$count) {
          return {
            count: res.body,
            users: []
          };
        } else {
          return {
            count: res.headers.get('count'),
            users: res.body.map(user => new User(user))
          };
        }
      })
    );
  }

  getCondoResidentUnmaskedField(
    condoId: string,
    params: EcondosQuery = {},
    userId: string,
    field: string
  ): Observable<{
    data: any;
  }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/users/${userId}/lgpd/${field}`, options).pipe(
      map((res: any) => ({
        data: res.body[field]
      }))
    );
  }

  getNumberOfUsersFromCondo(condoId: string): Observable<any> {
    return this.http.get(this.endPoint + condoId + '/users' + '?$count=true').pipe(map(n => n[0]));
  }

  getCondoUserById(condoId: string, userId: string, queryString?: string): Observable<User> {
    return this.http
      .get(this.endPoint + condoId + '/users/' + userId + (queryString || ''))
      .pipe(map((users: any) => (users ? (users.length ? new User(users[0]) : new User(users)) : null)));
  }

  getUserById(condoId: string, userId: string, query: EcondosQuery = {}): Observable<User> {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = {
      headers: new HttpHeaders(),
      params: params
    };

    return this.http
      .get(`${this.endPoint}${condoId}/users/${userId}`, options)
      .pipe(map((users: any) => (users ? (users.length ? new User(users[0]) : new User(users)) : null)));
  }

  addUserToCondo(condoId: string, user: any) {
    return this.http.post(`${this.endPoint}${condoId}/users/`, user);
  }

  removeUserFromCondo(condoId: string, userId: string, deleteDevice = false) {
    return this.http.delete(`${this.endPoint}${condoId}/users/${userId}?deleteDevice=${deleteDevice}`);
  }

  updateUserFromCondo(condoId: string, userId: string, user: any) {
    return this.http.put(`${this.endPoint}${condoId}/users/${userId}`, user);
  }

  addGatekeeperToCondo(condoId: string, userId: string) {
    return this.http.put(`${this.endPoint}${condoId}/users/${userId}/gatekeeper`, '');
  }

  removeGatekeeperFromCondo(condoId: string, userId: string) {
    return this.http.delete(this.endPoint + condoId + '/users/' + userId + '/gatekeeper');
  }

  addJanitorToCondo(condoId: string, userId: string) {
    return this.http.put(`${this.endPoint}${condoId}/users/${userId}/janitor`, '');
  }

  removeJanitorFromCondo(condoId: string, userId: string) {
    return this.http.delete(this.endPoint + condoId + '/users/' + userId + '/janitor');
  }

  addCondoUser(condoId: string, userId: string) {
    return this.http.put(`${this.endPoint}${condoId}/users/${userId}`, '');
  }

  approveUserOnCondo(condoId: string, userId: string) {
    return this.http.put(this.endPoint + condoId + '/users/' + userId + '/approve', '');
  }

  removeAdminFromCondo(condoId: string, userId: string) {
    return this.http.delete(this.endPoint + condoId + '/users/' + userId + '/admins');
  }

  addAdminToCondo(condoId: string, userId: string) {
    return this.http.put(this.endPoint + condoId + '/users/' + userId + '/admins', {});
  }

  changeCondoOwner(condoId: string, userId: string) {
    return this.http.put(this.endPoint + condoId + '/users/' + userId + '/owners', {});
  }

  changeUserRoles(condoId: string, userId: string, roles: UpdateUserRoles) {
    return this.http.put(`${this.endPoint}${condoId}/users/${userId}/roles`, { roles });
  }

  getResidencesFromUser(condoId: string, userId: string, params?: EcondosQuery): Observable<Residence[]> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    return this.http
      .get(`${this.endPoint}${condoId}/users/${userId}/residences`, { params: httpParams })
      .pipe(map((residences: any) => residences.map(residence => ResidenceBuilder.build(residence))));
  }

  importUsersToCondo(condoId: string, formData: FormData) {
    return this.http.post(`${this.endPoint}${condoId}/import/`, formData);
  }

  updateCondoPlan(
    condoId: string,
    condo: {
      daysOfTrial: number;
      plan: {
        name: string;
        features: Array<string>;
      };
    }
  ) {
    return this.http.put(this.endPoint + condoId + '/plan', condo);
  }

  updateHardwareConfig(
    condoId: string,
    hardware: {
      linear: { licence: string; enabled: boolean };
      hikvision: { enabled: boolean };
      controlId: { enabled: boolean };
      intelbras: { enabled: boolean };
      utech: { enabled: boolean };
      tasmota: { enabled: boolean };
      alphadigi: { enabled: boolean };
      garen: { enabled: boolean };
      lineares?: { name: string; licence: string }[];
      xpe: { enabled: boolean };
      mip1000ip: { enabled: boolean };
      avicam: { enabled: boolean };
    }
  ) {
    return this.http.put(this.endPoint + condoId + '/hardware', hardware);
  }

  updateHardwareIntelbrasConfig(condoId: string, config: IncontrolConfig) {
    return this.http.put(this.endPoint + condoId + '/intelbras/config', config);
  }

  getCondoUserLoginLogs(condoId: string): Observable<LoginLog[]> {
    return this.http.get(`${this.endPoint}${condoId}/login-logs`) as Observable<LoginLog[]>;
  }

  sendConfirmationMail(condoId: string, userId: string) {
    return this.http.get(`${this.endPoint}${condoId}/sendConfirmation/${userId}`);
  }

  getCondoUsersReport(condoId: string, query: EcondosQuery) {
    return downloadDataInChunks<User>(this.http, `${this.endPoint}${condoId}/users`, query, {
      model: User,
      numberOfRequests: 4
    });
  }

  getAllCondoResidents(condoId: string, query: EcondosQuery = {}) {
    return downloadDataInChunks<User>(this.http, `${this.endPoint}${condoId}/users`, query, { model: User });
  }

  getMyIP(): Observable<string> {
    return this.http.get(`${this.endPoint}my-ip`).pipe(map((res: { ip: string }) => res.ip));
  }

  validateIPPermission(condoId: string) {
    return this.http.get(`${this.endPoint}${condoId}/validate-ip`);
  }

  testConnectionSuperlogica(condoId: string, config: any) {
    return this.http.get(`${this.endPoint}${condoId}/integration/superlogica/test-connection`, { params: config });
  }

  getSuperlogicaConfig(condoId: string) {
    return this.http.get(`${this.endPoint}${condoId}/integration/superlogica`);
  }

  saveSuperlogicaConfig(condoId: string, superlogica: any) {
    return this.http.put(`${this.endPoint}${condoId}/integration/superlogica`, { ...superlogica });
  }

  convertUserToDependent(condoId: Condo['_id'], userId: User['_id']) {
    return this.http.put(`${this.endPoint}${condoId}/users/${userId}/convertToDependent`, {});
  }

  resetPassword(condoId: Condo['_id'], userId: User['_id']) {
    return this.http.get(`${this.endPoint}${condoId}/users/${userId}/resetPassword`);
  }

  updateUser(condoId: Condo['_id'], userId: User['_id'], user: Partial<User>) {
    return this.http.put(`${this.endPoint}${condoId}/users/${userId}/update`, { ...user });
  }

  emailAlreadyTaken(condoId: Condo['_id'], email: User['email']): Observable<{ emailAlreadyTaken: boolean; requiredFields: string[] }> {
    const query: EcondosQuery = {
      email
    };
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });
    return this.http
      .get(`${this.endPoint}${condoId}/3423849e-6045-4011-8920-9e9ad16d0115`, { params: httpParams })
      .pipe(map(({ emailAlreadyTaken, requiredFields }: any) => ({ emailAlreadyTaken, requiredFields })));
  }

  validateImportUserSpreadsheet(
    condoId: Condo['_id'],
    rowData: Partial<IImportUserRowData>[],
    documentsToValidate: string[]
  ): Observable<Partial<IImportUserRowData>[]> {
    return this.http.post(`${this.endPoint}${condoId}/0bbea43c-c65c-48c4-8793-c70d8042e8e5`, { rowData, documentsToValidate }).pipe(
      map((response: any) =>
        response.map(validation => ({
          name: validation.name || '',
          rg: validation.rg || '',
          cpf: validation.cpf || '',
          cnpj: validation.cnpj || '',
          ra: validation.ra || '',
          passport: validation.passport || '',
          documentsValidation: validation.documentsValidation || {},
          emailValidation: validation.emailValidation || {},
          email: validation.email || '',
          formattedBirthDate: validation.formattedBirthDate || '',
          role: validation.role || '',
          room: validation.room || '',
          type: validation.type || '',
          residence: validation.residence || '',
          status: validation.status || '',
          obs: validation.obs || '',
          specialNeedsDetails: validation.specialNeedsDetails || '',
          specialNeeds: validation.specialNeeds || ''
        }))
      )
    );
  }

  validateDocumentWithEmail(
    condoId: Condo['_id'],
    email: User['email'],
    number: ContactID['number'],
    type: ContactID['type']
  ): Observable<{
    valid: boolean;
  }> {
    const query: EcondosQuery = { email, number, type };
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });
    return this.http
      .get(`${this.endPoint}${condoId}/976e1088-eb79-421f-89d4-439624abafbc`, { params: httpParams })
      .pipe(map(({ valid }: any) => ({ valid })));
  }
}

export interface CondoSubscription {
  coupon?: string;
  name: string;
  numberOfResidences: number;
  document: { type: string; number: string; pictures?: any[] };
  phone: string;
  email: string;
  zipcode: number;
  address: string;
}
